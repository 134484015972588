@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.mainLoader {
  top: 0;
  left: 0;
  width: '100%';
  height: '100%';
  z-index: 50000;
  position: 'fixed';
  background: '#F8FFFD';
}

.btnClass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 10px;
  @media (max-width: 820px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .all {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
  }
  .btn {
    display: flex;
    align-items: center;
    @media (max-width: 820px) {
      justify-content: flex-end;
      width: 100%;
      flex-wrap: wrap;
    }
    button {
      background-color: transparent;
      border: 1px solid #39695b;
      margin-left: 15px;
      @media (max-width: 820px) {
      }
      span {
        color: #39695b;
      }
    }
    .btnClass {
      background-color: #673e9b !important;
      margin-top: 0px;
      margin-bottom: 0px;
      border: none !important;
      span {
        color: #ffffff !important;
      }
    }
    .defaultClass {
      background-color: #673e9b !important;
      margin-top: 0px;
      margin-bottom: 0px;
      span {
        color: #ffffff !important;
      }
    }
  }
}

.innerSecondDiv {
  margin-top: 60px;
  border-bottom: 1px solid #f7f9fc;
}

.borderTop {
  border-top: 1px solid #f7f9fc;
}

.bgColor {
  background-color: #fcfaff;
}

.numberClass {
  color: #673e9b !important;
}

.btnDropdown {
  border: 1px solid #673e9b !important;
  span {
    color: #673e9b !important;
  }
}

.imgDel {
  height: 20px;
  width: 20px;
}

.active {
  background: #673e9b !important;
  p {
    color: #ffffff !important;
  }
}

.inactiveClass:hover {
  background-color: #fcfaff !important;
  p {
    color: #673e9b !important;
  }
}

.noMatch {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  span {
    font-weight: bold;
    margin-right: 10px;
  }
  button {
    height: auto;
    padding: 6px 8px;
    border: 1px solid #673e9b;
    background-color: transparent;

    span {
      margin: 0;
      color: #673e9b !important;
    }
  }
}

.colorText {
  color: #673e9b !important;
}
.btnYes {
  background-color: #673e9b !important;
}
.btnNo {
  span {
    color: #673e9b !important;
  }
}

.chooseColumnBtn {
  border: 1px solid #673e9b !important;
  span {
    color: #673e9b !important;
  }
  @media (max-width: 453px) {
    margin-top: 4px;
  }
}

.gap20 {
  margin-bottom: 40px;
  padding: 14px 20px;
}

.ar1 {
  padding-left: 55px !important;
  justify-content: space-between !important;
}
.labelContainerClass {
  display: flex;
}
.mainElement {
  padding: 14px 20px;
}
.divElipsisName {
  div {
    white-space: nowrap;
    width: 230px !important;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}
.divElipsis {
  div {
    white-space: nowrap;
    width: 120px !important;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}

.rounded {
  cursor: pointer;
  position: relative;
  &:hover > div {
    display: block;
    width: max-content;
  }
}
.tooltipText {
  font-weight: 400;
  font-size: 14px;
  color: #696f7a;
  text-align: center;
}
.tooltipChild {
  width: 100%;
  height: 100%;
}
.tooltip {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: fit-content;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  padding: 10px;
  display: none;
  left: -10px;
  padding-right: 15px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

  &::before {
    content: '';
    top: -33px;
    left: 10px;
    z-index: 2010;
    clip-path: polygon(50% 61%, 0% 100%, 100% 100%);
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    height: 35px;
    width: 30px;
    position: absolute;
  }
}
.attorneyColumnEllipsis {
  div {
    white-space: nowrap;
    width: 160px !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}
