.mainDiv {
  max-width: 630px !important;
  .modalTitle {
    font-weight: 600;
    font-size: 18px;
    color: #673e9b;
    margin-bottom: 10px;
  }

  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .btnClassModal {
    height: 40px !important;
    width: 180px;
    background-color: #673e9b;
    &:disabled {
      background-color: #9da1a9 !important;
    }
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 180px;
    background-color: #fff;
    margin-top: 10px;
    span {
      color: #673e9b !important;
    }
  }
}

.upperDiv::-webkit-scrollbar {
  width: 7.5px !important; /* Remove scrollbar space */
  background: #e6e7e8 !important; /* Optional: just make scrollbar invisible */
  border-radius: 10px;
}
/* Optional: show position indicator in red */
.upperDiv::-webkit-scrollbar-thumb {
  background: #c7c8ca !important;
  border-radius: 10px;
}
