@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.overViewWraper {
  display: flex;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
  .sectionOne {
    flex: 1;
    .profileSection {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 7px;
      background-color: #ffffff;
      padding: 20px;
      margin-bottom: 20px;

      .profileSectionWraper {
        .datesSection {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          @media (max-width: $xs) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 20px;
          }
        }
        .educationSection {
          margin: 20px 0px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;

          @media (max-width: $xs) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 20px;
          }
        }
      }
    }
  }
  .seeAllSpan {
    cursor: pointer;
  }
  .sectiontwo {
    max-width: 350px;
    width: 100%;
    margin-left: 20px;
    @media (max-width: 1300px) {
      max-width: 100%;
      margin-left: 0px;
      margin-top: 20px;
    }
    .barHistoryHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0px;
      margin-top: 26px;
      .sideBarTag {
        @include normal-tag;
        color: $attorney1;
      }
      .count {
        @include small-text;
        background-color: $attorney1;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
      }
    }
    .historyMainSection {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 7px;
      background-color: #ffffff;
      padding: 20px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .commentSection {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      background-color: #ffffff;
      padding: 20px;
      border-radius: 8px;
      margin-bottom: 10px;
      span {
        overflow-wrap: break-word;
      }

      .commentSectionHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
.hovertext {
  border-width: 1;
  border-color: #39695b;
}
.labelTag {
  @include labelTag;
  margin-bottom: 5px;
}

.valueTag {
  @include header;
  padding-left: 1px;
  line-height: 21px;
  text-transform: capitalize;
}
.commentHead {
  @include header;
  color: $attorney1;
}
.date {
  color: $neutral2;
  font-size: 14px;
}
.ellipse {
  margin: 0 5px;
}
.commentValue {
  @include labelTag;
  color: $neutral2;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.modalHeader {
  text-align: center;
  p {
    @include p-small;
    color: $neutral1;
    line-height: 150%;
  }
  h3 {
    @include normal-tag;
    line-height: 100%;
    color: $attorney1;
  }
  button {
    width: 100%;
  }
  .cancelText {
    @include tableHeader;
    cursor: pointer;
  }
}

.attorneyForm {
  h3,
  .seeAllSpan,
  h4,
  .sideBarTag {
    color: #39695b !important;
  }
  .count {
    background-color: #39695b !important;
  }
  button {
  }
}
.datesSectionAttorney {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  @media (max-width: $xs) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
  }
}

.hoverDemoText {
  width: 260px;
  &::after {
    content: '';
    display: block;
  }
  @media (max-width: $xs) {
    width: 100px !important;
  }

  @media (max-width: 900px) {
    width: 140px !important;
  }
  @media (max-width: 1500px) {
    width: 200px;
  }
}

.hoverDemoAttorneyText {
  width: 350px;
  &::after {
    content: '';
    display: block;
  }
}
