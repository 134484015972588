@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.main {
  background-color: white;
  width: 100%;
  height: 308px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  border-radius: 8px;

  .innerdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
  .magnigyingGlass {
    margin-bottom: 16px;
  }
  text {
    @include p-small;
    line-height: 100%;
    margin-top: 16px;
    color: $neutral2;
  }
  button {
    min-height: 48px;
    max-height: 48px;
  }
}
.emptyText {
  color: #696f7a;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 16px;
}
