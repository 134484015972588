@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';
.wraper {
  margin-top: 30px;
  .headerBtn {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    .headerBtnContent {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 17px 16px;
      font-weight: 400;
      font-size: 14px;
      margin-right: 16px;
      .btnText {
        @include tableHeader;
        color: $neutral2;
        line-height: 16px;
      }
    }
  }
  .tblHeader {
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: 6fr 2fr 1fr 1fr 1fr 1fr 2fr 1.5fr 0.8fr;
    @media (max-width: $md) {
      grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr 2fr 1.5fr 0.8fr;
    }
    @media (max-width: $xs) {
      grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr 2fr 1.5fr 0.8fr;
    }
  }
  .tblBody {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #ffffff;
    padding: 17px 0px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 6.1fr 2fr 1fr 1fr 1fr 1fr 2fr 1.5fr 0.8fr;
    @media (max-width: $md) {
      grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr 2fr 1.5fr 0.8fr;
    }
    @media (max-width: $xs) {
      grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr 1fr 2fr 1.5fr 0.8fr;
    }
  }
  .tblElements {
    @include tableHeader;
    overflow-wrap: anywhere;
    padding-right: 20px;
  }
  .btnWraper {
    height: 40px;
    margin-top: 20px;
  }

  .editSection {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 15px;
    background-color: #f3f3f3;
    gap: 10px;
    display: flex;
    .sectionOne {
      width: 33%;
      h5 {
        margin-bottom: 8px;
      }
    }
    .sectionOneAttorney {
      width: 50%;
      h5 {
        margin-bottom: 8px;
      }
    }

    .sectionTwoAttorney {
      width: 50%;
      // flex: 1;
      h5 {
        margin-bottom: 8px;
      }
    }
    .sectionTwo {
      width: 33%;
      // flex: 1;
      h5 {
        margin-bottom: 8px;
      }
    }
    .sectionThree {
      width: 33%;
      h5 {
        margin-bottom: 8px;
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;

      line-height: 20px;
    }
  }
  .editSectionInternal {
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 15px;
    background-color: #f3f3f3;

    display: flex;
    .sectionOne {
      width: 100%;
      h5 {
        margin-bottom: 8px;
      }
    }
    .sectionTwo {
      flex: 1;
      margin-left: 10px;
    }
    p {
      font-weight: 400;
      font-size: 14px;

      line-height: 20px;
    }
  }
  .tblValue {
    @include tableHeader;
    font-weight: 400;
    display: grid;
    align-content: center;
    padding-right: 35px;
  }
}

.scrollDiv {
  @media (max-width: $md) {
    max-width: 900px;
    overflow-x: scroll;
    &::-webkit-scrollbar:horizontal {
      width: 5px; /* Remove scrollbar space */
      background: #e6e7e8; /* Optional: just make scrollbar invisible */
      height: 5px;
      border-radius: 8px;
      display: block !important;
    }
  }
  .innerScrollDiv {
    @media (max-width: $md) {
      width: 950px;
    }
  }
}
.modalHeader {
  text-align: center;
  .deleteText {
    @include normal-tag;
    line-height: 100%;
    color: $attorney1;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  p {
    @include p-small;
    padding: 0px 20px;
    color: $neutral1;
    line-height: 150%;
    margin-bottom: 24px;
  }
  button {
    width: 100%;
    margin-bottom: 30px;
  }
  .cancelText {
    @include tableHeader;
    color: $attorney1;
    cursor: pointer;
  }
}
