@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.main {
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0px;
    @media (max-width: $extra-small) {
      flex-direction: column;
      align-items: flex-start;
    }
    p {
      font-weight: 600;
      font-size: 32px;
      color: #304059;
      @media (max-width: $sm) {
        font-size: 24px;
      }
    }
    .btnDiv {
      @media (max-width: $extra-small) {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
      button {
        background: #304059;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.textField {
  margin: 20px 0px 40px 0px;
  max-width: 315px;
  z-index: 100;
  width: 100%;
  .field {
    img {
      height: 14px;
      width: 14px;
      top: 19px;
    }
  }
}

.gridClass {
  @media (max-width: $lg) {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
.gridWrapperClass {
  margin-bottom: 75px;
  @media (max-width: $sm) {
    margin-bottom: 0px;
  }
  @media (max-width: $extra-small) {
    margin-bottom: 0px;
  }
}

.mainHeight {
  background-color: #f6fafd;
  min-height: calc(100vh - 74px);
}
