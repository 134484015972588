@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.inputContainer {
  position: relative;
  width: 100%;
  label {
    @include font29;
    @include p-small;
    color: $neutral1;
    font-family: 'Montserrat';
    font-style: normal;
    margin-bottom: 5px;
    text-transform: capitalize;
    @media (max-width: $md) {
      margin-bottom: 8px;
    }
    @media (max-width: $xxs) {
      margin-bottom: 5px;
    }
  }
  input[type='range'] {
    padding: 0 !important;
  }

  input {
    background: #ffffff;
    border-radius: 6px;
    width: 100%;
    padding: 7px 10px;
    font-size: 14px;
    color: #2d2d32;
    margin-top: 4px;
    font-weight: 600;
    height: 41px;

    &::-webkit-credentials-auto-fill-button {
      visibility: hidden !important;
      position: absolute !important;
      right: 0 !important;
    }
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #9da1aa !important;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .icon {
    z-index: 200000px;
    background-color: #ffffff;
    position: absolute;
    right: 13px;
    top: 14px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    @media (max-width: $xl) {
      width: 24px;
      height: 24px;
      top: 14px;
    }
    @media (max-width: $lg) {
      width: 24px;
      height: 24px;
    }
  }
  .clearIcon {
    position: absolute;
    right: 14px !important;
    top: 13px;
    width: 24px !important;
    height: 24px !important;
    cursor: pointer;
    @media (max-width: $xl) {
      width: 30px;
      height: 30px;
      top: 13px;
    }
    @media (max-width: $lg) {
      width: 30px;
      height: 30px;
    }
  }

  .errorMessage {
    position: absolute;
    display: block;
    font-size: 12px;
    margin-left: 5px;
    color: #ff5050;
  }
}

input[type='password'] {
  &::placeholder {
    position: relative;
    top: 2px;
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
