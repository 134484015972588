@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.resetPopUp {
  margin-top: 20px;
  h6 {
    font-weight: 600;
    font-size: 18px;
    color: #39695b;
  }
  .field {
    img {
      top: 14px;
    }
  }
  .p {
    font-weight: 700;
    font-size: 14px;
    color: #363636;
    margin-top: 30px;
    padding: 0px 20px;
    margin-bottom: 20px;
  }
}
.upperDiv {
  height: 180px;
  overflow: auto;
  @include gridTwo(20, 20);
  .rowDiv {
    padding: 0px 20px;
    width: 100%;
  }
}
.mainBtnDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}
.btnClassModal {
  height: 40px !important;
  width: 200px;
  background-color: #39695b;
}
.btnClassUncheckModal {
  height: 40px !important;
  width: 180px;
  background-color: #fff;
  margin-top: 10px;
  span {
    color: #39695b !important;
  }
}

.upperDiv::-webkit-scrollbar {
  width: 7.5px !important; /* Remove scrollbar space */
  background: #e6e7e8 !important; /* Optional: just make scrollbar invisible */
  border-radius: 10px;
}
/* Optional: show position indicator in red */
.upperDiv::-webkit-scrollbar-thumb {
  background: #c7c8ca !important;
  border-radius: 10px;
}

.checkbox {
  border: 1px solid #39695b !important;
  &:after {
    border-color: #39695b !important;
  }
}
.zDynamicState {
  z-index: 100 !important;
}
.zDynamicState2 {
  z-index: 20 !important;
}
.zDynamicState3 {
  z-index: 300 !important;
}
.grid {
  @include gridFive(15, 15);
  position: relative;
  .field {
    input {
      background-color: transparent !important;
    }
    img {
      height: 14px;
      width: 14px;
      top: 19px;
    }
  }

  .datePicker {
    .date {
      margin-top: 7px !important;
      background-color: transparent !important;
    }
  }

  .resetDiv {
    display: flex;
    align-items: center;
    margin-top: 30px;
    position: relative;
    img {
      cursor: pointer;
    }
    .reset {
      font-weight: 600;
      font-size: 14px;
      color: #39695b;
      margin-left: 10px;
    }
  }
}
