.header {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: white;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #39695b;
  }
  .warningText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #363636;
  }
  .buttonStyle {
    background-color: #39695b;
    height: 48px;
    width: 237px;
    border-radius: 24px;
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .bottomText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #39695b;
    cursor: pointer;
  }
}

.inputWraper {
  margin-left: 30px !important;
}
.btnClass {
  margin: 20px;
  background-color: transparent;
  border: 1px solid red;
}
.btnClass1 {
  margin: 20px;
}
.btnClass1 {
  margin: 20px;
}
.btntext {
  color: blue !important;
}
.btnClass4 {
  margin: 20px;
  height: 30px;
  background-color: orange;
}
.btnClass5 {
  margin: 20px;
  height: 40px;
  background-color: red;
}
