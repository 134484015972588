@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.table {
  width: 100%;
  // padding: 0px 10px;
  border-spacing: 0px 0px !important;

  ::-webkit-scrollbar {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
  }
  /* Optional: show position indicator in #e6e7e8 */
  ::-webkit-scrollbar-thumb {
    background: #c7c8ca;
    border-radius: 8px;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: none;
  }
  tbody {
    tr {
      border-radius: 0px !important;
      height: 40px !important;
      font-size: 14px;
    }
  }
}
.tableWidth {
  max-width: 1920px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
  }
  /* Optional: show position indicator in #e6e7e8 */
  ::-webkit-scrollbar-thumb {
    background: #c7c8ca;
    border-radius: 8px;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: block !important;
  }
}
.th {
  padding: 0px 17px;
  text-align: left !important;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.17px;
  height: 40px;
  color: #363636;
  background-color: transparent;
  min-width: 180px;
  // border-top: 1px solid #e6e7e8 !important;
  div {
    background: transparent !important;
  }
  &:first-child {
    border-left: 1px solid #e6e7e8 !important;
    // border-radius: 5px 0px 0px 0px !important;
  }
  &:last-child {
    border-right: 1px solid #e6e7e8 !important;
    @media (max-width: 600px) {
      width: 305px !important ;
    }
  }
  td {
    border-radius: 0px !important;
  }
}
.table {
  min-width: 100%;
  overflow-x: scroll;
  margin-bottom: 12px;
  &:first-child {
    border-right: 1px solid #e6e7e8 !important;
  }
}

// tr:first-child {
//   td {
//     &:first-child {
//       border-left: 2px solid #e6e7e8 !important;
//     }
//     &:last-child {
//       border-right: 2px solid #e6e7e8 !important;
//     }
//   }
//   & > div {
//   }
// }
.paddingBottom {
  padding-bottom: 384px;
}
.thead {
  top: -15px !important;
  background-color: transparent !important;
}

.genericTableOnLoading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #363636;
  z-index: 4;
  opacity: 0.1;
}
.genericTableOffLoading {
  display: none;
}
.noDataDiv {
  padding: 10px 0px;
  text-align: justify;
  span {
    color: grey;
    font-size: 15px;
  }
}
.tableRow {
  td {
    background-color: transparent !important;
  }
  // box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  // border-radius: 8px;

  & > div {
    border-right: 1px solid #e6e7e8 !important;
    border-top: 1px solid #e6e7e8 !important;
    border-bottom: 1px solid #e6e7e8 !important;
    margin-top: 0px !important;
    height: 40px !important;
    &:last-child {
      border-bottom: 0px solid #e6e7e8 !important;
    }
  }
  &:last-child {
    & > div {
      border-bottom: 1px solid #e6e7e8 !important;
    }
  }

  &:last-child {
    border-left: 1px solid #e6e7e8 !important;
  }
  &:last-child {
    td {
      &:first-child {
        border-radius: 0px 0px 0px 5px !important;
      }
      &:last-child {
        border-radius: 0px 0px 5px 0px !important;
      }
      border-bottom: 1px solid #e6e7e8 !important;
    }
  }

  td {
    border-top: 1px solid #e6e7e8 !important;
    border-radius: 0px !important;
    &:first-child {
      border-left: 1px solid #e6e7e8 !important;
    }
    &:last-child {
      border-right: 1px solid #e6e7e8 !important;
    }
  }
}
