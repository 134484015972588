@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.mainDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row: auto;
  align-items: center;
  overflow: hidden;
  @media (max-width: 1080px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xs) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .leftInnerDiv {
    max-width: 450px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1080px) {
      padding: 40px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .crossIcon {
      top: 18px;
      height: 12px;
      width: 12px;
    }

    @media (max-width: $xs) {
      padding: 0px 20px;
    }

    .textFieldWraper {
      margin-top: 15px;
    }

    .logo {
      margin-bottom: 24px;
      width: 262px;
      height: 78px;
    }
    h2 {
      font-weight: 500;
      font-size: 24px;
      color: #304059;
      margin-bottom: 24px;
    }

    .para {
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 16px;
    }

    .buttonDiv {
      margin-top: 24px;
      width: 100%;

      .loginButton {
        width: 100%;
        max-width: 100%;
        height: 48px;
        background-color: #304059;
      }
    }
  }
}

.loadingDiv {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid transparent;
    border-bottom: 2px solid black;
    width: 100px;
    height: 100px;
  }
}

.rightInnerDiv {
  height: 100vh;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 1080px) {
    display: none;
  }
}
