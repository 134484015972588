@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.wraper {
  display: flex;
  .items {
    cursor: pointer;
    font-family: 'Montserrat';
    padding: 0px 10px;
    padding-bottom: 6px;
    border-bottom: 1px transparent;
    margin-right: 10px;
  }

  span {
    @include header;
  }
}

.scrollDiv {
  @media (max-width: $md) {
    max-width: 800px;
    overflow-x: scroll;
  }
  .innerScrollDiv {
    @media (max-width: $md) {
      width: 900px;
    }
  }
}
