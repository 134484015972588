@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.mainMain {
  max-width: 529px !important;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  max-height: calc(100vh - 440px);

  &::-webkit-scrollbar {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: #c7c8ca;
    border-radius: 8px;
  }

  &::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: none !important;
  }
  &::-webkit-scrollbar:horizontal {
    display: none;
  }
}
.editContainerTop {
  box-shadow: none !important;
  border: 1px solid #e6e7e8 !important;
}

.practiceMain {
  height: 100%;
  .textField {
    margin: 20px 0px 20px 0px;
    max-width: 315px;
    width: 100%;
    .field {
      img {
        height: 14px;
        width: 14px;
        top: 19px;
      }
    }
  }
  .practiceText {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.17px;
    color: #363636;
    padding-bottom: 16px;
    padding-left: 0px;
  }
  .noRecords {
    font-weight: 500;
    font-size: 16px;

    padding-left: 16px;
  }
  .editContainer {
    margin: 0px !important;
    border-radius: 0px;
  }
  .cardsNew {
    width: 529px;
    border: 1px solid #e6e7e8 !important;
    border-bottom: 0px solid !important;
    // border-radius: 5px 5px 0px 0px !important;
    &:first-child {
      border-radius: 5px 5px 0px 0px !important;
      // overflow: hidden;
    }
    &:last-child {
      overflow: hidden;
      border-radius: 0px 0px 5px 5px !important;
      border-bottom: 1px solid #e6e7e8 !important;
    }
    &:hover {
      &:last-child {
        overflow: visible;
        & > div {
          & > div {
            & > div {
              & > div {
                &:first-child {
                  & > div {
                    & > div {
                      top: -60px !important;
                      &::before {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.tooltip {
          top: -30px !important;
        }
      }
    }
  }
  .card {
    div {
      border-radius: 0px !important;
    }
    .cardComp {
      div {
        border-radius: 0px !important;
        margin-top: 0px;
      }
      @media (max-width: $sm) {
        flex-direction: row;
        align-items: center;
      }
    }
  }
  .btn {
    margin: 20px 0;
    background-color: #304059;
  }
}
.modal {
  max-width: 300px !important ;
  & div {
    margin-top: 0px !important;
  }
  p {
    color: #363636 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  h6 {
    text-align: center !important;
  }
}
