@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';
.noRecords {
  margin-top: 40px;
}
.p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #304059;
  margin-left: 30px;
}
.p2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4d6075;
  margin-top: 18px;
  margin-left: 30px;
}
.p3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #363636;
}
.p4 {
  margin-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #9da1aa;
}

.wraper {
  margin-top: 20px;
  .headerBtn {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    .headerBtnContent {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 15px;
      font-weight: 400;
      font-size: 14px;
      margin-right: 10px;
      .btnText {
        @include tableHeader;
        color: $neutral2;
      }
    }
  }
  .tblHeader {
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .tblBody {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #ffffff;
    padding: 17px 0px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .tblElements {
    @include tableHeader;
    overflow-wrap: anywhere;
    padding-right: 10px;
  }
  .btnWraper {
    height: 40px;
    background-color: #304059;
    margin-top: 20px;
  }

  .editSection {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 15px;
    background-color: #f3f3f3;
    display: flex;
    .sectionOne {
      width: 50%;
      h5 {
        margin-bottom: 8px;
      }
    }
    .sectionTwo {
      flex: 1;
      margin-left: 10px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .tblValue {
    @include tableHeader;
    font-weight: 400;
    display: grid;
    align-content: center;
  }
}

.scrollDiv {
  @media (max-width: $md) {
    max-width: 900px;
    overflow-x: scroll;
  }
  .innerScrollDiv {
    @media (max-width: $md) {
      width: 950px;
    }
  }
}
.modalHeader {
  text-align: center;
  p {
    @include p-small;
    color: $neutral1;
    line-height: 150%;
    margin: 10px 0px;
  }
  h3 {
    @include normal-tag;
    line-height: 100%;
    color: $primary2;
    margin-top: 16px;
  }
  button {
    width: 100%;
    margin: 10px 0px;
    background-color: $primary2;
  }
  .cancelText {
    color: $primary2;
    @include tableHeader;
    cursor: pointer;
  }
}

.buttonDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 20px;
}
.btnClass {
  background-color: #304059;
  width: 140px;
}
.noRecordsButton {
  background-color: #304059;
  width: 250px;
}
.btnClass2 {
  align-items: center;
  border: solid 1px #304059;
  background-color: transparent;
  width: 140px;
  span {
    color: #304059 !important;
  }
}
.loading {
  height: 20vh;
  position: relative;
  div {
    height: 100%;
  }
}
