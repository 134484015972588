@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.main {
  padding: 15px 0px;
  max-width: 529px !important;
  width: 100%;
  overflow-x: auto;
  @media (max-width: $sm) {
  }
  .tableHeader {
    display: flex;
    justify-content: space-between;
    // @include gridTwo(30, 30);
    padding: 0px 15px 16px 15px;
    @media (max-width: $lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    .practiceText {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.17px;
      color: #363636;
    }
  }
  .card {
    background: #ffffff;
    min-height: 54px;
    border: 1px solid #e6e7e8 !important;
    border-bottom: 0px solid !important;
    border-radius: 0px !important;
    padding: 5px 16px;
    display: flex;
    align-items: center;
    width: 100%;
    &:nth-of-type(2) {
      border-radius: 5px 5px 0px 0px !important;
    }
    &:last-child {
      border-radius: 0px 0px 5px 5px !important;
      border-bottom: 1px solid #e6e7e8 !important;
    }
    .innerChild {
      // @include gridTwo(30, 30);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @media (max-width: $lg) {
        grid-template-columns: repeat(2, 1fr);
      }
      .field {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
        max-width: 300px;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.17px;
        color: #363636;
      }
      input {
        margin-top: 0px;
        height: 100% !important;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.17px;
        color: #363636;
      }
      img {
        cursor: pointer;
      }
    }
  }
}
.btn {
  background-color: #304059;
  max-width: 225px;
  width: 100%;
}

.maxWidthClass {
  @media (max-width: $sm) {
    max-width: 760px;
    overflow-x: auto;
  }
}

.innerFlex1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 650px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    text-align: right;
    color: #696f7a;
    max-width: 415px;
    @media (max-width: 650px) {
      margin-bottom: 10px;
      max-width: 100%;
      text-align: left;
    }
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputClass {
  input {
    text-align: end;
    width: 50px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.17px;
    color: #363636;
  }
}

.mainDiv1 {
  .img {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    img {
      cursor: pointer;
    }
  }
  button {
    margin-top: 40px;
    width: 100%;
    max-width: 100%;
  }
}
.okButton {
  background-color: #304059;
  border-radius: 24px;
  width: 150px;
  margin-top: 20px;
}

.modalTitle {
  font-weight: 600;
  font-size: 18px;
  color: #304059;
  margin-bottom: 10px;
  text-align: center;
}
.divElipsis {
  div {
    white-space: nowrap;
    width: 300px !important;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}

.rounded {
  cursor: pointer;
  position: relative;
  &:hover > div {
    display: block;
    width: fit-content;
    max-width: 300px;
  }
}
.tooltipText {
  font-weight: 400;
  font-size: 14px;
  color: #696f7a;
  overflow-wrap: break-word;
  text-align: center;
}
.tooltipChild {
  width: 100%;
  height: 100%;
}
.tooltip {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: fit-content;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  padding: 10px;
  display: none;
  left: -10px;
  padding-right: 15px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

  &::before {
    content: '';
    top: -33px;
    left: 10px;
    z-index: 2010;
    clip-path: polygon(50% 61%, 0% 100%, 100% 100%);
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    height: 35px;
    width: 30px;
    position: absolute;
  }
}
