@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.bgDiv {
  background-color: #fcfaff;
  min-height: calc(100vh - 72px);
  .whiteWrapper {
    background: #ffffff;
    border-bottom: 1px solid #c7c8ca;
    padding-top: 45px;
    .flexDiv {
      display: flex;
      justify-content: space-between;

      align-items: flex-end;
      @media (max-width: $lg) {
        flex-direction: column;
      }
      .leftDiv {
        display: flex;
        align-items: center;
        width: 100%;
        @media (max-width: $sm) {
          flex-direction: column;
        }
        .profileImgContainer {
          margin-right: 35px;
        }
      }
      .rightDiv {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-height: 50px;
        @media (max-width: $lg) {
          margin: 20px 0px;
        }
        @media (max-width: $sm) {
          margin-top: 20px;
        }
        .btnClass {
          height: 50px !important;
          background: #673e9b !important;
        }
        .cancelBtn {
          height: 50px !important;
          background-color: transparent !important;
          border: 2px solid #673e9b;
          margin-right: 20px;
        }
        .btnText {
          color: #673e9b !important;
        }
      }
    }
    .tabs {
      display: flex;
      align-items: center;
      margin-top: 35px;
      p {
        font-weight: 500;
        font-size: 16px;
        color: #696f7a;
        border-bottom: 1px solid transparent;
        padding: 8px 10px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
}
.userInfoWraper1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 120px;

  @media (max-width: $lg) {
    @include gridThree(15, 20);
    margin-left: 20px;
    margin-right: 0px;
    grid-template-columns: repeat(3, 1fr) !important;
  }
  @media (max-width: $sm) {
    margin-left: 0px;
    grid-gap: 10px 10px;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
.userInfoWraper {
  @include gridFive(15, 20);
  margin-right: 90px;

  @media (max-width: $lg) {
    margin-left: 20px;
    margin-right: 0px;

    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $sm) {
    margin-left: 0px;
    grid-gap: 10px 10px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .inputWraper {
    margin-left: 30px !important;
  }
}

.editClassInfo {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  @media (max-width: $sm) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    margin-right: 0px;
  }
  span {
    margin-bottom: 4px;
  }
}
.editClassInfo1 {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
  @media (max-width: $sm) {
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    margin-right: 0px;
  }
}

.imgLogoBack {
  display: none;
  @media (max-width: $sm) {
    display: block;
    margin: 0px 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.imgLogoBack1 {
  display: block;
  margin-right: 30px;
  cursor: pointer;
  @media (max-width: $sm) {
    display: none;
  }
}
.fieldPosition {
  label {
    text-transform: none !important;
  }
}

.popper {
  transform: translate(-4px, 50px) !important;
  @media (max-width: $md) {
    transform: translate(-75px, 50px) !important;
  }
  @media (max-width: 850px) {
    transform: translate(-110px, 50px) !important;
  }
  @media (max-width: $sm) {
    transform: translate(-4px, 50px) !important;
  }
}
.popper1 {
  @media (max-width: 680px) {
    transform: translate(-40px, 50px) !important;
  }
  @media (max-width: 600px) {
    transform: translate(-75px, 50px) !important;
  }
  @media (max-width: 555px) {
    transform: translate(-115px, 50px) !important;
  }
  @media (max-width: 450px) {
    transform: translate(-4px, 50px) !important;
  }
}
