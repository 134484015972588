@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.retrybtnOtherButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 32px 0px;
  width: 80%;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .btnClass {
    height: 50px !important;
    width: 210px;
    margin-left: 20px;
    background-color: #673e9b;
    @media (max-width: 700px) {
      margin-bottom: 20px;
    }
  }
  .btnLoaderClass {
    border-color: #673e9b #673e9b #673e9b #fff;
  }
  .btnClassUncheck {
    height: 50px !important;
    max-width: 220px;
    width: 100%;
    margin-left: 20px;
    background-color: #fff;
    border-width: 3;
    border: 1px solid #673e9b;
    span {
      color: #673e9b !important;
    }
    @media (max-width: 700px) {
      margin-bottom: 20px;
    }
  }
}
.retrybtn {
  padding: 10px;
  background-color: #673e9b;
  border-radius: 0px 12px 12px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  height: 72px;
  position: fixed;
  z-index: 3;
  top: 50%;
  img {
    cursor: pointer;
  }
}
.flexInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .delBtn {
    margin-bottom: 20px;
    border: 1px solid #ce4343 !important;
    border-radius: 24px !important;
    background-color: transparent !important;
    span {
      color: #ce4343 !important;
    }
  }
  .p {
    font-weight: 400;
    font-size: 14px;
    color: #696f7a;
    @media (max-width: 700px) {
      margin-bottom: 20px;
      text-align: right;
    }
  }
}

.rounded {
  cursor: pointer;
  position: relative;
  &:hover > div {
    display: block;
  }
}
.tooltipText {
  font-weight: 400;
  font-size: 14px;
  color: #696f7a;
  text-align: center;
  white-space: nowrap;
  word-break: keep-all;
  span {
    font-weight: bold;
    white-space: nowrap;
    word-break: keep-all;
  }
}
.tooltipChild {
  width: 100%;
  height: 100%;
}
.tooltip {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: fit-content;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  padding: 10px;
  display: none;
  left: -20px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  &::before {
    content: '';
    top: -32px;
    z-index: 2010;
    clip-path: polygon(50% 61%, 0% 100%, 100% 100%);
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    height: 35px;
    width: 30px;
    position: absolute;
  }
}
.attorneyColumnEllipsis {
  p {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}

.width {
  width: 200px !important;
}

.slotContainer {
  display: flex;
  align-items: center;
  .index {
    min-width: 27px;
  }
  img {
    margin-left: 10px;
  }
}

.cityText {
  width: 98px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.editIconDiv {
  position: relative;
  text-align: right;
}

.loaderDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.bolder {
  font-weight: 700 !important;
}

.rightDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 50px;
  margin-top: 32px;
  .btnClass {
    height: 50px !important;
    background: #673e9b !important;
  }
  .cancelBtn {
    height: 50px !important;
    background-color: transparent !important;
    border: 2px solid #673e9b;
    margin-right: 20px;
  }
  .btnText {
    color: #673e9b !important;
  }
}
.rounded {
  cursor: pointer;
  position: relative;
  &:hover > div {
    display: block;
    width: max-content;
  }
}
.tooltipText {
  font-weight: 400;
  font-size: 14px;
  color: #696f7a;
  text-align: center;
}
.tooltipChild {
  width: 100%;
  height: 100%;
}
.tooltip {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: fit-content;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  padding: 10px;
  display: none;
  left: -10px;
  padding-right: 15px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

  &::before {
    content: '';
    top: -33px;
    left: 10px;
    z-index: 2010;
    clip-path: polygon(50% 61%, 0% 100%, 100% 100%);
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    height: 35px;
    width: 30px;
    position: absolute;
  }
}
