@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.wraper {
  padding-bottom: 30px;

  .topText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #363636;
    margin-bottom: 18px;
    margin-top: 40px;
  }
  .scrollDiv {
    margin-top: 20px;
    @media (max-width: $md) {
      max-width: 900px;
      overflow-x: scroll;
      &::-webkit-scrollbar:horizontal {
        width: 5px; /* Remove scrollbar space */
        background: #e6e7e8; /* Optional: just make scrollbar invisible */
        height: 5px;
        border-radius: 8px;
        display: block !important;
      }
    }
    .innerScrollDiv {
      @media (max-width: $md) {
        width: 950px;
      }
    }
  }
  .btnWraper {
    background-color: #a93e71;
  }
}
.modalHeader {
  text-align: center;
  .deleteText {
    @include normal-tag;

    line-height: 100%;
    color: $client1;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  p {
    @include p-small;
    padding: 0px 20px;
    color: $neutral1;
    line-height: 150%;
    margin-bottom: 24px;
  }
  button {
    width: 100%;
    margin-bottom: 30px;
    background-color: $client1;
  }
  .cancelText {
    @include tableHeader;
    color: $client1;
    cursor: pointer;
  }
}
