@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';
.p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #304059;
  margin-top: 30px;
  max-width: 472px;
  width: 100%;
  overflow-wrap: anywhere;
}
.p2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4d6075;
  margin-top: 18px;
  margin-bottom: 40px;
}
.zDynamicState {
  z-index: 100 !important;
}
.mainDiv {
  background-color: #ffffff;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  border-radius: 8px;
  margin-top: 16px;
  .mainDiv2 {
    padding: 32px;
  }
}
.p3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #363636;
  padding-bottom: 32px;
  .p4 {
    font-size: 16px;
    color: #363636;
    font-weight: 200;
  }
}
.img1 {
  margin-top: 24px;
}
.p5 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #363636;
  margin-top: 32px;
}
.childDiv {
  background-color: #ffffff;
  @include gridThree(10, 10);
  @media (max-width: $lg) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
  }
  margin-top: 24px;
  padding-bottom: 10px;
  .p1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #363636;
  }
  .p2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #363636;
  }
}
.p6 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #363636;
  margin-top: 32px;
}
.p7 {
  margin-top: 40px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #363636;
}
.p8 {
  margin-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #696f7a;
}
.btnClass {
  align-items: center;
  background: #304059;
  width: 140px;
  margin-top: 32px;
}

.buttonDiv {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}
.attorneyEditFields {
  margin-bottom: 15px;

  @media (max-width: $md) {
    margin-bottom: 15px;
  }
  @media (max-width: $xs) {
    margin-bottom: 15px;
  }
}
