@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.mainHeader {
  .innerFlex {
    display: flex;
    justify-content: space-between;
    @media (max-width: $xs) {
      flex-direction: column;
    }
    .attorney {
      font-weight: 600;
      font-size: 32px;
      color: #673e9b;
    }
    .btns {
      display: flex;
      align-items: center;
      @media (max-width: $xs) {
        justify-content: flex-end;
        align-items: flex-end;
        flex-wrap: wrap;
        width: 100%;
      }
      button {
        img {
          height: 15px;
          width: 15px;
        }
      }
      .btn {
        background-color: transparent;
        border: 1px solid #673e9b;
        margin-right: 20px;

        span {
          color: #673e9b;
        }
      }
      .btnClass {
        background-color: #673e9b;
        @media (max-width: $xs) {
          margin-left: 10px !important;
          margin-top: 10px;
        }
      }
      .btnClassComparison {
        border: 1px solid #673e9b;
        background-color: transparent;
        margin-right: 20px !important;
        span {
          color: #673e9b;
        }
        &:disabled {
          border: none;
          background-color: #9da1aa;
          span {
            color: #363636 !important;
          }
        }
        @media (max-width: $xs) {
          margin-right: 0px !important;
          margin-left: 10px !important;
          margin-top: 10px;
        }
      }
    }
  }
  .grid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
    position: relative;

    .textField {
      flex: 1;
      margin-right: 20px;
      margin-top: 7px;
      min-width: 400px;
      @media (max-width: 1100px) {
        max-width: 400px;
        flex: none;
        min-width: auto;
      }
      @media (max-width: 800px) {
        max-width: 100%;
        margin-right: 0px;
        min-width: 100%;
      }
      .field {
        input {
          background-color: transparent !important;
        }
        img {
          height: 14px;
          width: 14px;
          top: 19px;
        }
      }
    }
    .datePicker {
      max-width: 160px;
      width: 100%;
      margin-right: 20px;
      margin-top: 7px;
      @media (max-width: $sm) {
        max-width: 100%;
        margin-right: 0px;
      }

      .date {
        margin-top: 7px !important;
        background-color: transparent !important;
      }
    }
    .statusBar {
      max-width: 245px;
      width: 100%;
      margin-right: 20px;
      margin-top: 7px;
      @media (max-width: $sm) {
        max-width: 100%;
        margin-right: 0px;
      }
    }
    .resetDiv {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: 30px;
      position: relative;
      img {
        cursor: pointer;
      }
      .reset {
        font-weight: 600;
        font-size: 14px;
        color: #673e9b;
        margin-left: 10px;
      }
    }
  }
}
.dynamicClass {
  min-height: 41px !important;
}

.zDynamicState4 {
  z-index: 400 !important;
}

.btnDropdown {
  border: 1px solid #673e9b;
  @media (max-width: $xs) {
    margin-right: 0px !important;
    margin-left: 10px !important;
    margin-top: 10px;
  }

  span {
    color: #673e9b !important;
  }
}

.check {
  background-color: transparent !important;
  border: 1px solid #673e9b !important;
  span {
    color: #673e9b !important;
  }
}

.endDatePopper {
  @media (max-width: 1340px) {
    left: unset !important;
    right: 0 !important;
  }
}
