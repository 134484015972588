.mainDiv {
  max-width: 760px !important;

  .modalTitle {
    font-weight: 600;
    font-size: 18px;
    color: #673e9b;
    margin-bottom: 10px;
  }

  .table {
    overflow-x: auto;
    max-width: 700px;
    .header {
      display: flex;
      align-items: center;
      background-color: #f6fafd;
      border-radius: 8px;
      padding: 10px 5px;
      margin-top: 10px;
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        width: 25%;
        color: #363636;
      }
    }
    .body {
      min-height: 130px;
      overflow-y: auto;
      &::-webkit-scrollbar:vertical {
        width: 5px; /* Remove scrollbar space */
        background: #e6e7e8; /* Optional: just make scrollbar invisible */
        height: 5px;
        border-radius: 8px;
        display: block !important;
      }
      .innerDiv {
        display: flex;
        align-items: center;
        padding: 10px 20px 0px 5px;
        margin-top: 10px;
        .title {
          display: flex;
          flex-direction: column;
          width: 25%;
          p {
            font-weight: 400;
            font-size: 14px;
            overflow-wrap: anywhere;
            color: #696f7a;
            padding-right: 24px;
            width: initial;
          }
          span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            color: #673e9b !important;
            overflow-wrap: anywhere;
            color: #696f7a;
            padding-right: 24px;
          }
        }
        p {
          font-weight: 400;
          font-size: 14px;
          width: 25%;
          overflow-wrap: anywhere;
          color: #696f7a;
          padding-right: 24px;
        }
      }
    }
  }

  .div {
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: flex-end;
    img {
      width: 20px;
      height: 20px;
      margin-left: 25px;
      cursor: pointer;
    }
  }

  .mainBtnDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    .btnClassUncheckModal {
      height: 40px !important;
      width: 210px;
      background-color: #fff;
      margin-top: 10px;
      span {
        color: #673e9b !important;
      }
    }
  }
  .noRecord {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 181px;
    color: #696f7a;
    font-weight: 500;
    padding: 20px 0 0 0;
    p {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}

.modalLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
}
