.mainDiv {
  max-width: 300px !important;
  .modalTitle {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #673e9b;
    margin-bottom: 10px;
  }
  .iconRefresh {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
  }
  .p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    text-align: center;
    color: #363636;
  }

  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .btnClassModal {
    height: 40px !important;
    width: 100%;
    background-color: #673e9b;
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 100%;
    background-color: #fff;
    margin-top: 10px;
    span {
      color: #673e9b !important;
    }
  }
}
