.mainDiv {
  max-width: 400px !important;

  .modalTitle {
    font-weight: 600;
    font-size: 18px;
    color: #673e9b;
    margin-bottom: 10px;
  }

  .para {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
    color: #363636;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    color: #363636;
  }
  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
  }
  .btnClassModal {
    height: 40px !important;
    width: 180px;
    background-color: #673e9b;
    &:disabled {
      background-color: #9da1a9;
    }
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 210px;
    background-color: #fff;
    margin-top: 10px;
    span {
      color: #673e9b !important;
    }
  }
}
