@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.backdropDiv {
  background-color: transparent;
  z-index: 10;
  position: fixed;
  inset: 0;
}
.positionDiv {
  position: absolute;
  z-index: 401;
  cursor: pointer;
  width: 223px;
  right: 0px;

  margin-top: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  filter: drop-shadow(0px -1px 13px rgba(0, 0, 0, 0.1));
  p {
    font-weight: 400;
    font-size: 16px;
    color: #304059;
    padding: 8px 16px;
    border-bottom: 1px solid #f6fafd;
    &:nth-last-child(1) {
      border-bottom: none;
    }
  }
}
