.mainDiv {
  max-width: 380px !important;
  min-height: auto !important;
  .modalTitle {
    font-weight: 600;
    font-size: 18px;
    color: var(--primary-color-of-column-change) !important;

    margin-bottom: 10px;
  }
  .upperDiv {
    height: 290px;
    overflow: auto;
    margin-top: 25px;

    &::-webkit-scrollbar {
      width: 5px !important;
      background: #e6e7e8 !important;
      border-radius: 8px !important;
      display: block !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #c7c8ca;
      border-radius: 8px;
    }

    .rowDiv {
      padding: 0px 20px;
      width: 100%;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffffff;
    }
  }
  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .btnClassModal {
    height: 40px !important;
    width: 200px;
    background-color: var(--primary-color-of-column-change) !important;
    &:disabled {
      background-color: #9da1aa !important;
    }
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 180px;
    background-color: #fff;
    margin-top: 10px;
    span {
      color: #39695b !important;
    }
  }
  .btnReset {
    height: 40px !important;
    width: 180px;
    background-color: #fff;
    margin-top: 10px;
    span {
      white-space: nowrap;
      color: #a93e71 !important;
    }
  }
}

.checkbox {
  border: 1px solid #39695b !important;
}

.crossImg {
  display: flex;
  justify-content: flex-end;
  img {
    cursor: pointer;
  }
}
.selectAllDiv {
  padding: 15px 20px;
  margin-top: 25px;
  background: #f6fafd;
  border-radius: 8px;
  .checkbox1 {
    border: 1px solid #39695b !important;
  }
  .checkboxClass {
    color: #363636;
    font-weight: 700;
  }
}

.modalBody {
  max-height: 100vh !important;
}
