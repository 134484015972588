@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.profileContainer {
  border-bottom: 1px solid #c7c8ca;
  background-color: #ffffff;
}
.wraper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 35px 0;
  @media (max-width: $sm) {
    min-height: 500px;
  }
  @media (max-width: $lg) {
    flex-direction: column;
  }
  @media (max-width: $sm) {
    justify-content: center;
  }
  .container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    @media (max-width: $sm) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
  .profileImgContainer {
    display: flex;
    @media (min-width: $md) {
      margin-right: 15px;
    }
    .editImgBox {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 38px;
      right: 28px;
      background-color: #39695b;
    }
  }
  .userInfoWraper {
    .labelspan {
      color: $neutral1;
      @include p-small;
    }
    .valueSpan {
      @include header;
      color: $neutral1;
      line-height: 21px !important;
      a {
        color: $neutral1;
        line-height: 21px !important;
      }
    }
    .editTextSec {
      display: flex;
      align-items: center;
    }
    .liEditTextSec {
      width: 250px;
      display: flex;
      align-items: center;
      @media (max-width: $sm) {
        width: 100%;
      }
    }
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr) 2fr 1fr;
    grid-gap: 15px 20px;
    grid-auto-rows: auto;
    @media (max-width: $sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;
    }

    @media (max-width: 1280px) {
      margin-left: 20px;
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1130px) {
      margin-left: 20px;
      grid-template-columns: repeat(2, 1fr) !important;
    }
    @media (max-width: $sm) {
      margin-left: 0px;
      grid-gap: 10px 10px;
      grid-template-columns: repeat(2, 1fr) !important;
    }
    @media (max-width: 450px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .editClassInfo {
      margin-right: 50px;
      display: flex;
      flex-direction: column;
      h1 {
        white-space: nowrap;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        line-height: 50px;
        box-sizing: border-box;
        pointer-events: none;
      }

      h1:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 48px;
        z-index: 1;
        pointer-events: initial;
      }

      .tooltip {
        width: auto;
        display: none;
        background-color: #c7c8ca;
        border-radius: 8px;
        padding: 10px;
        opacity: 0;
        position: absolute;
        transition: opacity 0.5s ease;
        margin-top: 46px;
      }

      h1:hover + .tooltip {
        display: block;
        transition: opacity 0.5s ease;
        opacity: 1;
      }

      @media (max-width: $sm) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0px;
        margin-right: 0px;
      }
    }
    .inputWraper {
      margin-left: 30px !important;
    }
  }
  .btnClass {
    height: 50px !important;
    font-weight: 600;
  }
  .cancelBtn {
    height: 50px !important;
    background-color: transparent;
    border: 2px solid #39695b;
    margin-right: 15px;
  }
  .btnText {
    color: #39695b !important;
  }
  .cancelBtn1 {
    height: 50px !important;
    background-color: transparent;
    border: 2px solid #304059;
    margin-right: 5px;
  }
  .btnText1 {
    color: #304059 !important;
  }
  .btnSection {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $lg) {
      margin: 20px 0px;
    }
    @media (max-width: $sm) {
      margin-top: 20px;
    }
  }
}

.imgLogoBack {
  display: none;
  @media (max-width: $sm) {
    display: block;
    margin: 0px 10px;
    width: 20px;
    height: 20px;
  }
}
.imgLogoBack1 {
  display: block;
  margin: 0px 10px;
  cursor: pointer;
  margin-right: 20px;
  @media (max-width: $sm) {
    display: none;
  }
}

.marginClass {
  margin-right: 15px;
  @media (max-width: $sm) {
    margin-right: 0px;
  }
}

.buttonSection {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.colorProp {
  color: #304059 !important;
}
.zDynamic {
  z-index: 4001 !important;
}

.header {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  img {
    margin: 20px 0;
  }
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #39695b;
  }
  .warningText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #363636;
    margin-bottom: 10px;
  }
  .buttonsWrapper {
    padding: 20px 0;
  }
  .buttonStyle {
    background-color: #39695b;
    height: 48px;
    width: 237px;
    border-radius: 24px;
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .bottomText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #39695b;
    cursor: pointer;
    padding: calc(3.77193px + 0.4386vw) calc(7.4386px + 1.27193vw)
      calc(3.77193px + 0.4386vw) calc(8.66667px + 0.83333vw);
    margin-top: 10px;
  }
}
