@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';
.wraper {
  margin-top: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  .sectionOne {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    @media (max-width: $md) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 20px;
    }
    @media (max-width: $xs) {
      grid-row-gap: 20px;
      grid-template-columns: 1fr;
    }
  }
  .subjectSection {
    margin-top: 10px;
    margin-bottom: 20px;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
  .sectionTwo {
    display: grid;
    grid-gap: 20px;
    margin-top: 10px;
    grid-template-columns: 1fr;
    @media (max-width: $md) {
      grid-template-columns: 1fr;
    }
  }
  .btnSection {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    img {
      cursor: pointer;
    }
    .btnClass {
      height: 50px !important;
      background-color: $primary2;
      margin-left: 20px;
    }
  }
}
