.wraper {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  padding: 42px;
  margin-top: 10px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  &.small {
    padding: 12px;
    .btnWraper {
      height: 44px;
    }
  }
  .btnWraper {
    background-color: transparent;
    border: 1px solid #39695b;
    height: 47px;
  }
  .btnClass {
    color: #39695b !important;
  }
  .isBlueText {
    color: #304059 !important;
  }
  .isBlue {
    background-color: transparent;
    border: 1px solid #304059;
    height: 47px;
  }
}
.noRecord {
  display: flex;
  flex-direction: column;
  align-items: center;
}
