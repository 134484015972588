$primary1: #223149;
$primary2: #304059;
$primary3: #4d6075;
$primary4: #f6fafd;

$secondary1: #f58020;
$secondary2: #f8971d;
$secondary3: #f8dcc3;

$neutral1: #363636;
$neutral2: #696f7a;
$neutral3: #9da1aa;
$neutral4: #c7c8ca;
$neutral5: #e6e7e8;
$neutral6: #727272;

$tag1: #727272;
$tag2: #30a15d;
$tag3: #36c170;
$tag4: #6fcf97;
$tag5: #deffeb;
$tag6: #f8fffd;
$tag7: #ce4343;
$tag8: #daa6ab;
$tag9: #ffcccc;
$tag10: #ffeaea;
$tag11: #784db0;
$tag12: #80658c;
$tag13: #d0d2ff;
$tag14: #836565;
$tag15: #2475d0;
$tag16: #dfeeff;
$tag17: #ffd350;
$tag18: #fff2cd;

$attorney1: #39695b;
$attorney2: #f8fffd;

$client1: #a93e71;

$position1: #673e9b;
$position2: #fcfaff;

$client1: #a93e71;
$client2: #fffafc;

$btn: #39695b;
$text: #b9b9b9;
$white: #ffffff;
$header: #6e6d6d;
$subtile: #8b8b8b;
$danger: #ff5050;
$borderColor: #d9d9d9;
$bg: #fafafa;
$title: #2d2d32;
