@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.wraper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  @media (max-width: $lg) {
    flex-direction: column;
  }
  .container {
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: $sm) {
      flex-direction: column;
    }
  }
  .profileImgContainer {
    display: flex;
    .editImgBox {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 38px;
      right: 28px;
      background-color: #39695b;
    }
  }
  .userInfoWraper {
    @include gridThree(15, 20);

    @media (max-width: $lg) {
      margin-left: 20px;
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: $sm) {
      margin-left: 0px;
      grid-gap: 10px 10px;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 450px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .inputWraper {
      margin-left: 30px !important;
    }
  }
  .btnClass {
    height: 50px !important;
    background-color: #a93e71;
  }
  .cancelBtn {
    height: 50px !important;
    background-color: transparent;
    border: 2px solid #a93e71;
    margin-right: 5px;
  }
  .btnText {
    color: #a93e71 !important;
  }
  .btnSection {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $lg) {
      margin: 20px 0px;
    }
    @media (max-width: $sm) {
      margin-top: 20px;
    }
  }
}

.imgLogoBack {
  display: none;
  @media (max-width: $sm) {
    display: block;
    margin: 0px 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.imgLogoBack1 {
  display: block;
  margin-right: 30px;
  cursor: pointer;
  @media (max-width: $sm) {
    display: none;
  }
}

.marginClass {
  margin-right: 15px;
  @media (max-width: $sm) {
    margin-right: 0px;
  }
}

.buttonSection {
  display: flex;
  margin-top: 10px;
  padding-left: 10px;
  @media (max-width: $lg) {
    justify-content: flex-end;
    margin-top: 15px;
  }
}

.date {
  margin-top: 6px !important;
  margin-right: 10px !important;
}
.datePickerDiv {
  @include gridTwo(10, 10);
  @media (max-width: $lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 950px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.userInfoWraper1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 120px;

  @media (max-width: 1280px) {
    @include gridThree(15, 20);
    margin-left: 20px;
    margin-right: 0px;
    grid-template-columns: repeat(3, 1fr) !important;
  }
  @media (max-width: $sm) {
    margin-left: 0px;
    grid-gap: 10px 10px;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.labelspan {
  color: $neutral1;
  @include p-small;
}
.valueSpan {
  @include header;
  color: $neutral1;

  line-height: 21px !important;
}
.editClassInfo {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  @media (max-width: $sm) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    margin-right: 0px;
  }
}
.editClassInfo1 {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
  @media (max-width: $sm) {
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    margin-right: 0px;
  }
}
.buttonsDiv {
  display: flex;
  padding-left: 10px;
  @media (max-width: 1280px) {
    justify-content: flex-end;
    margin-top: 15px;
  }
}
