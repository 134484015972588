@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';
.historyMainSection {
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $xs) {
    flex-direction: row;
    align-items: flex-start;
  }
  .title {
    color: $attorney1;
    @include header;
  }
  .titleDiv {
    display: flex;
    flex-direction: column;
    @media (max-width: $xs) {
      width: 100%;
      span {
        font-size: 16px;
        width: 100%;
      }
    }
  }
  .value {
    @include labelTag;
    color: $neutral2;
    line-height: 14px;
    margin-top: 3px;
    display: flex;
    flex-wrap: wrap;
  }
}
.btnClass {
  margin-left: 25px;
  @media (max-width: $xs) {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-left: 0px;
  }
}
