.mainWrapper {
  //   display: flex;
  //   gap: 5px;
}
.chipWrap {
  background-color: #39695b;
  border-radius: 15px;
  text-align: center;
  color: white;
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
}
