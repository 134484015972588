@import 'styles/_mixin.scss';
@import 'styles/_colors.scss';

.modalStyle {
}

.modalWrapper {
  .slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 75%;
    /* Full-width */
    margin: 30px 0;
    height: 15px;
    /* Specified height */
    background: #d3d3d3;
    /* Grey background */
    outline: none;
    /* Remove outline */
    opacity: 0.7;
    /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: 0.2s;
    /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
    border-radius: 50px;
  }

  /* Mouse-over effects */
  .slider:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 25px;
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    border-radius: 25px;

    background: $btn;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
  }

  .slider::-moz-range-thumb {
    width: 25px;
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    background: $btn;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
  }

  .modal {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    overflow-y: auto;
    max-width: 451px;
    height: fit-content;
    overflow-y: auto;
    background: white;

    &::-webkit-scrollbar:vertical {
      display: none;
    }

    .body {
      padding: 0px;
    }

    .topText {
      @include normal-tag;
      color: var(--primary-color);
      margin: 0px 0px 20px;
    }

    .uploadBtnDiv {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      .uploadBtn {
        width: 200px;
        cursor: pointer;
      }
    }
  }
}

.editImgBox {
  width: 30px !important;
  height: 30px !important;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: var(--primary-color);

  img {
    margin: 0 !important;
  }
}
.deleteImgBox {
  width: 30px !important;
  height: 30px !important;
  border-radius: 30px;
  border: 0.5px solid #ce4343;
  cursor: pointer;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute;
  bottom: 0px !important;
  right: -30px !important;

  img {
    margin: 0 !important;
  }
}

.box {
  width: 300px;
  height: 300px;
}

.avatarWrapper {
  position: relative;
  width: fit-content;
}

.cropModal {
  display: flex;
  justify-content: center;
  height: 100%;
  min-width: 451px;
  min-height: 481px;
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 250000;
  padding: 20px 20px;
  background-color: rgba(0, 0, 0, 0.6);
}

.uploadDiv {
  border: 2px solid grey;
  border-style: dashed;
  border-radius: 8px;
  width: 387px;
  height: 249px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .uploadText {
    @include p-small;
  }
}
.modalHeader {
  text-align: center;
  .deleteText {
    @include normal-tag;
    line-height: 100%;
    color: var(--primary-color);
    margin-top: 16px;
    margin-bottom: 8px;
  }
  p {
    @include p-small;
    padding: 0px 20px;
    color: $neutral1;
    line-height: 150%;
    margin-bottom: 24px;
  }
  button {
    width: 100%;
    margin-bottom: 30px;
  }
  .cancelText {
    @include tableHeader;
    color: $attorney1;
    cursor: pointer;
  }
}
.buttonStyleBlue {
  background-color: #223149;
}

.buttonStyle {
  background-color: #39695b;
}
