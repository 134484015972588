@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.main {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  border-radius: 8px;
  padding: 16px;
  .btnSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    .img {
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
