.mainDiv {
  max-width: 450px !important;
  .crossImg {
    display: flex;
    justify-content: flex-end;
    img {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
  .iconRefresh {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    img {
      width: 35px;
      height: 35px;
    }
  }

  .modalTitle {
    font-weight: 600;
    font-size: 18px;
    color: #673e9b;
    margin-bottom: 10px;
    text-align: center;
  }
  .p {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #696f7a;
  }

  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .btnClassModal {
    height: 50px !important;
    width: 300px;
    background-color: #673e9b;
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 250px;
    background-color: #fff;
    margin-top: 20px;
    span {
      color: #673e9b !important;
    }
  }
}
