@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.searchSelect {
  position: relative;

  .field {
    margin-top: 10px;
    img {
      width: 15px;
      height: 15px;
    }
  }

  .searchDropdown {
    margin-top: 10px;
    width: 100%;
    min-height: 120px;
    overflow-y: auto;
    z-index: 2000;
    position: relative;
    &::-webkit-scrollbar:vertical {
      width: 5px; /* Remove scrollbar space */
      background: #e6e7e8; /* Optional: just make scrollbar invisible */
      height: 5px;
      border-radius: 8px;
      display: block !important;
    }

    .innerDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      p {
        text-transform: capitalize;
        font-size: 14px;
      }
      button {
        height: 24px;
        max-width: 100px;
        padding: 3px 10px;
        border-radius: 5px;
      }
    }
  }
}
.innerDiv1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f6fafd;
  border-radius: 8px;
  margin-top: 20px;
  padding: 10px 0px;
  h6 {
    font-weight: 700;
    font-size: 14px;
    color: #363636;
  }
}

.pName {
  font-weight: 700;
  font-size: 14px;
  color: #363636;
  margin-bottom: 0px;
}
.checkbox {
  border: 1px solid #673e9b;
}
.checkbox:after {
  border: solid #673e9b !important;
  border-width: 0px 3px 3px 0px !important;
}

.liStatus {
  width: 145px !important;
  max-width: unset !important;
}
