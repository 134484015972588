@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.main {
  .header {
    display: flex;
    padding-top: 25px;
    justify-content: space-between;
    flex-wrap: wrap;

    .sync {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #363636;
      flex-flow: row wrap;
      @media (min-width: $sm) {
        min-width: 600px;
      }
      p {
        line-height: 1.5;
        padding: 5px 8px 5px 0;
        color: #363636;
      }
      strong {
        line-height: 1.5;
        cursor: pointer;
      }
    }

    .clickable {
      text-decoration: underline;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
      .clickable:active {
      text-decoration: none;
      color: gray;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .faketext{
      cursor:default;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
  .buttonView {
    display: flex;
    align-items: center;
    @media (max-width: $xs) {
      width: 100%;
      justify-content: flex-end;
    }
  }
  .nowButton {
    background-color: #304059;
    border-radius: 8px;
    margin-left: 16px;
    @media (max-width: $xs) {
      img {
        margin: 0;
      }
      span {
        display: none;
      }
    }
    @media (max-width: $sm) {
      img {
        margin: 0;
      }
      span {
        display: none;
      }
    }
  }
  .btnTitle {
    color: #304059;
  }
  .btnTitle1 {
    color: #ffffff;
  }
  .cumulativeButton {
    background-color: #ffffff;
    border-radius: 8px;
    margin-left: 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    @media (max-width: $xs) {
      img {
        margin: 0;
      }
      span {
        display: none;
      }
    }
    @media (max-width: $sm) {
      img {
        margin: 0;
      }
      span {
        display: none;
      }
    }
  }
  h3 {
    color: #304059;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 6px;
    font-family: 'Montserrat';
  }
  .parentDiv {
    margin-top: 20px;
    .section1 {
      @include gridThree(20, 20);
    }
    .section2 {
      @include gridTwo(20, 20);
      grid-template-columns: 0.98fr 2fr;
      margin-top: 20px;
    }
    .section3 {
      @include gridThree(20, 20);
      margin-top: 20px;
    }
  }
}
.loadingDiv {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid transparent;
    border-bottom: 2px solid black;
    width: 100px;
    height: 100px;
  }
}
.gridStyle {
  cursor: pointer;
}
.header1 {
  @media (max-width: $xs) {
    flex-direction: column;
  }
}
.snapShotDiv {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 30px 0px;
  .snapCard {
    width: 50%;
    background-color: #e6eaef;
    border-radius: 8px;
    padding-top: 30px;
    padding-bottom: 15px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    @media (max-width: 1500px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    span {
      color: #304059;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 14px;
    }
    .snapInnerDiv {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 30px;
      @media (max-width: 565px) {
        flex-direction: column;
      }
      .snapBox {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 5px;
        height: 90px;
        width: 22%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        @media (max-width: 565px) {
          width: 90%;
          margin: 5px 0px;
        }
        .snapValue {
          color: #545454;
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 24px;
        }
        .snapPerc {
          color: #545454;
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 18px;
        }
        .snapTitle {
          color: #545454;
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }
}
