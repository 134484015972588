.mainDiv {
  max-width: 400px !important;
  .modalTitle {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #673e9b;
    margin-bottom: 10px;
  }

  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 410px;
  }
  .btnClassModal {
    height: 40px !important;
    width: 100%;
    background-color: #673e9b;

    &:disabled {
      background-color: #9da1a9;
    }
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 100%;
    background-color: #fff;
    margin-top: 10px;
    span {
      color: #673e9b !important;
    }
  }
}
