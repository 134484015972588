@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.note {
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    @include p-small;
    color: $neutral1;
    margin-bottom: 5px;
    text-transform: capitalize;
    @media (max-width: $md) {
      margin-bottom: 8px;
    }
    @media (max-width: $xxs) {
      margin-bottom: 5px;
    }
  }

  textarea {
    @include p-small;
    background: #ffffff;
    box-shadow: none;
    outline: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 10px;
    resize: none;
    &::placeholder {
      @include p-small;
      font-size: 14px;
      font-weight: 600;
      color: #9da1aa;
      @media (max-width: $lg) {
        font-size: 14px;
      }
    }
  }
}

.errorMessage {
  display: block;
  font-size: 12px;
  margin-left: 5px;
  color: #ff5050;
}
