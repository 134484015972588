.mainDiv {
  max-width: 301px !important;

  .modalTitle {
    font-weight: 600;
    font-size: 18px;
    color: #304059;
    margin-bottom: 10px;
    text-align: center;
  }
  .iconRefresh {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
  }
  .p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    text-align: center;
    color: #304059;
  }

  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
  }
  .btnClassModal {
    height: 40px !important;
    width: 250px;
    background-color: #304059;
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 250px;
    background-color: #fff;
    margin-top: 10px;
    span {
      color: #304059;
    }
  }
}
h6 {
  font-weight: 600;
  font-size: 18px;
  color: #304059;
  margin-bottom: 5px;
  text-align: center;
}
