@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

.main .react-datepicker__day--keyboard-selected {
  border-radius: 5px !important;
}

.react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day--outside-month {
  font-family: Montserrat !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  letter-spacing: 0.22px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 10px !important;
  font-family: Montserrat !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  letter-spacing: 0.22px !important;
  color: #8b8b8e !important;
}
.react-datepicker__month-container
  .react-datepicker__header
  .react-datepicker__day-names
  .react-datepicker__day-name {
  color: var(--primary-color) !important;
  text-transform: uppercase !important;
}

.react-datepicker-popper {
  inset: 18px auto auto 35px !important;
  padding: 0px !important;
  font-family: Montserrat;
  z-index: 3 !important;
  left: 0px !important;
  overflow-y: hidden !important;
  margin-top: -22px;
  transform: translate(-4px, 50px) !important;
}
table .react-datepicker-popper {
  z-index: 1 !important;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
  display: none !important;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  display: none !important;
}

.react-datepicker {
  background: #ffffff !important;
  border: none !important;
  border-radius: 8px !important;
  margin-top: 0px !important;
  font-family: Montserrat !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12) !important;
  left: 0;
  right: 0;
  bottom: 0 !important;
  overflow-y: hidden !important;
}
.react-datepicker__header {
  background-color: #ffffff !important;
  border-bottom: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected
  .react-datepicker__month-text--in-selecting-range
  .react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range {
  border-radius: 50% !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range,
.react-datepicker__day .react-datepicker__year-wrapper {
  max-width: 300px !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent;
  color: #3c3844;
  border-radius: 50% !important;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
}

.react-datepicker__day {
  color: #3c3844;
  background-color: transparent;
  border-radius: 50% !important;
}
.react-datepicker__day--outside-month {
  color: #8b8b8e !important;
}

.react-datepicker__day--selected {
  color: white !important;
  border-radius: 50% !important;
}
.react-datepicker__year-wrapper .react-datepicker__year-text--selected {
  border-radius: 3px !important;
  background-color: var(--primary-color) !important;
}

.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--primary-color) !important;
  border-radius: 3px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: transparent;
  color: black;
}

.react-datepicker__day--today {
  background-color: transparent;
  color: #3c3844;
}

.react-datepicker__day--in-selecting-range {
  background-color: transparent !important;
  color: #3c3844 !important;
}

.react-datepicker__day--selecting-range-start {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.react-datepicker__month-text--today {
  font-weight: 500 !important;
}
.react-datepicker__month-text--keyboard-selected {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
  border-radius: 3px !important;
}

.reactDatepickerMonthContainer .react-datepicker__month-container {
  width: 300px !important;
}

.reactDatepickerMonthContainer .react-datepicker__month-wrapper {
  display: flex;
  justify-content: space-evenly;
  padding: 6px;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
}

.react-datepicker__close-icon::after {
  background-color: var(--primary-color) !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: var(--primary-color) !important;
  color: white !important;
}
