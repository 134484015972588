.mainDiv {
  max-width: 301px !important;
  height: 320px !important;

  .modalTitle {
    font-weight: 600;
    font-size: 18px;
    color: #673e9b;
    margin-bottom: 10px;
    text-align: center;
  }
  .para {
    text-align: center;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #363636;
  }
  .iconRefresh {
    display: flex;
    justify-content: center;
    margin: 10px 0 20px 0;
    img {
      width: 40px;
    }
  }
  .p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    text-align: center;
    color: #363636;
  }

  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
  }
  .btnClassModal {
    height: 40px !important;
    width: 180px;
    background-color: #673e9b;
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 180px;
    background-color: #fff;
    margin-top: 10px;
    span {
      color: #673e9b !important;
    }
  }
}
.modalBody {
  height: 100%;
}
