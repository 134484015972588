@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.main {
  .heading {
    font-weight: 600;
    font-size: 32px;
    color: #304059;
    padding: 32px 0px;
    @media (max-width: $sm) {
      font-size: 24px;
    }
  }
  .tabsDiv {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .p {
      font-weight: 500;
      font-size: 16px;
      color: #696f7a;
      padding: 5px 10px;
      margin-right: 15px;
      border-bottom: 1px solid transparent;
      cursor: pointer;
    }
  }
}

.mainHeight {
  background-color: #f6fafd;
  min-height: calc(100vh - 74px);
}

.mainTabs {
  max-width: 750px;
  overflow-x: hidden;
}
.mainWrapper {
  position: relative;
  min-height: 400px;
  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    .loading {
      border-top: 2px solid black;
      border-left: 2px solid black;
      border-right: 2px solid transparent;
      border-bottom: 2px solid black;
      width: 100px;
      height: 100px;
    }
  }
}
