@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.wraper {
  margin-top: 20px;
  margin-top: 30px;
  .comment {
    @include tableHeader;
    color: #363636;
  }
  .mainTab {
    .dateSec {
      @include tableHeader;
      font-weight: 400;
      color: $neutral2;
    }
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #ffffff;
    padding: 13px;
    border-radius: 7px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .header {
      display: flex;
      justify-content: flex-end;
    }
    .head {
      display: flex;
      justify-content: flex-end;
      margin-right: 100px;
    }
    .sectionOne {
      width: 100%;
      padding: 10px;
    }
    .btnSection {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      margin-right: 15px;
      margin-left: 42px;
      img {
        cursor: pointer;
      }
    }
  }
  .addCmntSection {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #ffffff;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    .btnSection {
      margin-top: 30px;
      display: flex;
      img {
        cursor: pointer;
      }
      justify-content: flex-end;
      .btnClass {
        height: 50px !important;
        margin-left: 20px;
      }
    }
  }
  .btnWraper {
    height: 40px;
    margin-top: 20px;
  }
  .commentVal {
    @include tableHeader;
    font-weight: 400;
  }
}

.scrollDiv {
  margin-top: 28px;
  @media (max-width: $md) {
    max-width: 900px;
    overflow-x: scroll;
    &::-webkit-scrollbar:horizontal {
      width: 5px; /* Remove scrollbar space */
      background: #e6e7e8; /* Optional: just make scrollbar invisible */
      height: 5px;
      border-radius: 8px;
      display: block !important;
    }
  }
  .innerScrollDiv {
    @media (max-width: $md) {
      width: 950px;
    }
  }
}
.modalHeader {
  text-align: center;
  .deleteText {
    @include normal-tag;
    line-height: 100%;
    color: $attorney1;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  p {
    @include p-small;
    padding: 0px 20px;
    color: $neutral1;
    line-height: 150%;
    margin-bottom: 24px;
  }
  button {
    width: 100%;
    margin-bottom: 30px;
  }
  .cancelText {
    @include tableHeader;
    color: $attorney1;
    cursor: pointer;
  }
}
