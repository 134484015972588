.table {
  width: 100%;
  border-spacing: 0px 0px !important;

  // padding: 0px 10px;

  &:first-child {
    border-right: 1px solid #e6e7e8 !important;
  }

  ::-webkit-scrollbar {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #c7c8ca;
    border-radius: 8px;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: none !important;
  }
}
.tableWidth {
  max-width: 1920px;
  overflow-y: auto;
  // ::-webkit-scrollbar {
  //   width: 5px; /* Remove scrollbar space */
  //   background: #e6e7e8; /* Optional: just make scrollbar invisible */
  //   height: 5px;
  //   border-radius: 8px;
  // }

  // /* Optional: show position indicator in red */
  // ::-webkit-scrollbar-thumb {
  //   background: #c7c8ca;
  //   border-radius: 8px;
  // }

  // ::-webkit-scrollbar:vertical {
  //   width: 5px; /* Remove scrollbar space */
  //   background: #e6e7e8; /* Optional: just make scrollbar invisible */
  //   height: 5px;
  //   border-radius: 8px;
  //   display: block !important;
  // }
}
.th {
  padding: 15px 10px;
  text-align: left !important;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: #363636;
  background-color: transparent;
  min-width: 180px;
  border-bottom: 1px solid #e6e7e8;
}

.thead {
  top: -15px !important;
  background-color: transparent !important;
}
.tableRow {
  // box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  box-shadow: none;
  font-weight: 400;
  font-size: 14px;
  td {
    border-bottom: 1px solid #e6e7e8;
    border-radius: 0px !important;
    background-color: #fffafc !important;
  }
  &:hover {
    td {
      background-color: #f5f5f5 !important;
      cursor: pointer;
    }
  }
}

.genericTableOnLoading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #363636;
  z-index: 4;
  opacity: 0.1;
}
.genericTableOffLoading {
  display: none;
}
.noDataDiv {
  display: flex;
  height: 102px;
  padding: 10px 0px;
  border-radius: 8px;
  text-align: justify;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 5px 20px rgb(169 186 199 / 25%);
  .noRecord {
    position: absolute;
    border-radius: none;
    left: calc(50% - 42px);
    box-shadow: none !important;
    width: fit-content !important;
  }

  span {
    color: grey;
    font-size: 15px;
  }
}
