@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.mainSidebar {
  overflow-y: auto;
  max-height: calc(100vh - 74px);
  // disable text selection functionality (highlight text)
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .head {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    .p {
      font-weight: 600;
      font-size: 14px;
      color: #4d6075;
    }
  }
  .innerFirstDiv {
    margin-bottom: 60px;

    .inner1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      &.active {
        background: var(--primary-color-of-stats) !important;
        p {
          color: #ffffff !important;
        }
      }
      .p {
        font-weight: 700;
        font-size: 14px;
        color: #363636;
      }
      &.inactive:hover {
        background-color: #f8fffd;
        p {
          color: var(--primary-color-of-stats) !important;
        }
      }
    }
    .inner2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 20px 14px 50px;
      cursor: pointer;
      border-left: 1px solid transparent;
      &.active {
        background: var(--primary-color-of-stats) !important;

        p {
          color: #ffffff !important;
        }
      }

      &.inactive:hover {
        background-color: #f8fffd;
        p {
          color: var(--primary-color-of-stats) !important;
        }
      }

      .p {
        font-weight: 700;
        font-size: 14px;
        color: #363636;
      }
    }
    .inner3 {
      display: flex;
      justify-content: space-between;
      padding: 14px 20px 14px 76px;
      cursor: pointer;
      border-left: 1px solid transparent;

      &.active {
        background: var(--primary-color-of-stats) !important;

        p {
          color: #ffffff !important;
        }
      }
      &.inactive:hover {
        background-color: #f8fffd;
        p {
          color: var(--primary-color-of-stats) !important;
        }
      }

      .p {
        font-weight: 700;
        font-size: 14px;
        color: #363636;
      }
    }
  }
}

.textColor {
  color: var(--primary-color-of-stats);
  font-weight: 700;
  font-size: 14px;
  margin-left: auto;
}

.main {
  position: relative;
  padding-top: 45px;
  .leftIcon {
    position: absolute;
    cursor: pointer;
    right: -6px;
    top: 42px;
    z-index: 3000;
  }
  img {
    cursor: pointer;
  }
  .leftIcon2 {
    position: absolute;
    right: -25px;
    top: 42px;
    transform: rotateY(180deg);
  }
}

.showSmall {
  display: none;
  width: 16px;
  height: 16px;
  @media (max-width: $md) {
    display: block !important;
  }
}

.hiddenSmall {
  width: 16px;
  height: 16px;
  display: block;
  @media (max-width: $md) {
    display: none;
  }
}
.flexMain {
  display: flex;
  justify-content: center;
  align-items: center;
}
