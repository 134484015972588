@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.attorneyDiv {
  background-color: white;
  width: 100%;
  max-height: 500px;
  height: 100%;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  .topDiv {
    display: flex;
    align-items: center;
  }
  h1 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 40px;
    color: #39695b;
  }
  .text {
    color: #39695b;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
  }
  .gridSection {
    margin-top: 10px;
    display: flex;

    justify-content: space-around;
    .gridView {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: center;
      width: 100%;
      padding: 10px;
      margin-right: 10px;
      background-color: #f8fffd;
      .p1 {
        color: #363636;
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 24px;
      }
      .iconView {
        display: flex;
      }
      .p2 {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 14px;
        color: #363636;
      }
    }
  }
}

@media (max-width: $xs) {
  .gridSection {
    flex-direction: column;
  }
  .gridView {
    margin-bottom: 10px;
  }
}
