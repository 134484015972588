@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';
.noRecords {
  margin-top: 40px;
}
.p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #304059;
  margin-left: 30px;
}
.p2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4d6075;
  margin-top: 18px;
  margin-left: 30px;
}
.p3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #363636;
}
.p4 {
  margin-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #696f7a;
}
.gridClass {
  @media (max-width: $lg) {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
.mainTable {
  @media (max-width: 1200px) {
    width: 1180px;
  }
  .tableHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr;
    justify-content: space-between;
    align-items: center;
    margin-top: 42px;
    margin-bottom: 20px;
    padding: 0px 24px;
    p {
      font-weight: 600;
      font-size: 14px;
      color: #363636;
      cursor: pointer;
      flex-basis: 300px;
    }
  }
  .rows {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr;
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    border-radius: 8px;
    padding: 13px 24px;
    margin-bottom: 15px;
    .imgDiv {
      display: flex;
      align-items: center;
      flex-basis: 300px;
      margin-right: 10px;
      margin-left: 0px;
      .avatar {
        cursor: pointer;
        margin: 0px;
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.17px;
      color: #363636;
      flex-basis: 300px;
      margin-right: 10px;
      padding: 5px 0px;
    }
  }
}
.maxWidthClass {
  margin-bottom: 10px;

  @media (max-width: 1200px) {
    overflow-x: scroll;
    max-width: 1200px;
    &::-webkit-scrollbar:horizontal {
      width: 5px; /* Remove scrollbar space */
      background: #e6e7e8; /* Optional: just make scrollbar invisible */
      height: 5px;
      border-radius: 8px;
      display: block !important;
    }
  }
}
.flexSwitch {
  flex-basis: 300px;
  margin-right: 10px;
}
.emptyDiv {
  flex-basis: 150px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    cursor: pointer;
    margin-left: 10px;
  }
}
.userName {
  flex-basis: 0px !important;
  margin-left: 16px;
}

.rowsBody {
  max-height: calc(100vh - 255px);
  overflow-y: scroll;
  margin-top: 10px;
  .innerHeight {
    height: 100%;
  }
}
.modal {
  max-width: 300px !important ;
  & div {
    margin-top: 0px !important;
  }
  p {
    color: #363636 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  h6 {
    text-align: center !important;
  }
}

.pTag {
  flex-basis: 600px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.addButton {
  background-color: #304059;
}
.buttonDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 30px;
}
.btnClass {
  background-color: #304059;
  width: 140px;
}
.noRecordsButton {
  background-color: #304059;
  width: 200px;
}
.modalHeader {
  text-align: center;
  p {
    @include p-small;
    color: $neutral1;
    line-height: 150%;
    margin: 20px 0px;
  }
  h3 {
    @include normal-tag;
    line-height: 100%;
    color: #304059;
  }
  button {
    width: 100%;
    margin: 20px 0px;
    background-color: #304059;
  }
  .cancelText {
    @include tableHeader;
    cursor: pointer;
  }
}

.btnClass2 {
  align-items: center;
  border: solid 1px #304059;
  background-color: transparent;
  width: 140px;
  span {
    color: #304059 !important;
  }
}

.loading {
  height: 20vh;
  position: relative;
  div {
    height: 100%;
  }
}
