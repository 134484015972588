@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.mainAddAttorney {
  .attorney {
    font-weight: 600;
    font-size: 32px;
    color: #673e9b;
  }
  .innerAdd {
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    border-radius: 8px;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 600px;
    .img {
      height: 56px;
      width: 56px;
    }
    .record {
      font-weight: 400;
      font-size: 14px;
      color: #696f7a;
      margin: 16px 0px;
    }
    .btnClass {
      height: 48px;
      background-color: #673e9b !important;
    }
  }
}
