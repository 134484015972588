@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';
.wraper {
  margin-top: 30px;
  margin-bottom: 30px;
  .tblHeader {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 1.75fr 0.25fr;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
    margin-top: 48px;
    font-size: 14px;
    padding-left: 16px;
    overflow-x: scroll;
    @media (max-width: $md) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr 1fr;
    }
  }
  .spanClass {
    flex-basis: 250px;
    font-size: 14px;
    @media (max-width: $md) {
      margin-right: 10px;
    }
    @media (max-width: $xs) {
      margin-right: 10px;
    }
  }
  .spanClass1 {
    flex-basis: 210px;
    font-size: 14px;

    @media (max-width: $md) {
      margin-right: 10px;
    }
    @media (max-width: $xs) {
      margin-right: 10px;
    }
  }
  .spanClass2 {
    flex: 1;
    flex-basis: 995px;
    padding-right: 10px;
    font-size: 14px;
    overflow: hidden;
    padding-right: 20px;
    @media (max-width: $md) {
      margin-right: 10px;
    }
    @media (max-width: $xs) {
      margin-right: 10px;
    }
  }
  .tblElements {
    margin-left: 10px;
    align-items: center;
    display: flex;
  }
  .tblBody {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #ffffff;
    padding: 0px 17px;
    // border-radius: 5px;
    // margin-top: 10px;
    display: grid;
    height: 50px;
    font-size: 14px !important;
    border: 1px solid #e6e7e8 !important;
    &:first-child {
      border-radius: 5px 5px 0px 0px !important;
    }

    grid-template-columns: repeat(4, 1fr) 1.75fr 0.25fr;
    align-items: center;
    @media (max-width: $md) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr 1fr;
    }
    @media (max-width: $xs) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr 1fr;
    }
  }
}
.btnWraper {
  height: 40px;
  margin-top: 20px;
}

.scrollDiv {
  @media (max-width: 1500px) {
    overflow-x: scroll;
    max-width: 1500px;
    &::-webkit-scrollbar:horizontal {
      width: 5px; /* Remove scrollbar space */
      background: #e6e7e8; /* Optional: just make scrollbar invisible */
      height: 5px;
      border-radius: 8px;
      display: block !important;
    }
  }
  .innerScrollDiv {
    table-layout: fixed;
    min-width: 100%;
    display: table;
  }
}
.rowIcons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    cursor: pointer;
  }
  margin-right: 15px;
}
.modalHeader {
  text-align: center;
  .deleteText {
    @include normal-tag;
    line-height: 100%;
    color: $attorney1;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  p {
    @include p-small;
    padding: 0px 20px;
    color: $neutral1;
    line-height: 150%;
    margin-bottom: 24px;
  }
  button {
    width: 100%;
    margin-bottom: 30px;
  }
  .cancelText {
    @include tableHeader;
    color: $attorney1;
    cursor: pointer;
  }
}
.imgDiv {
  margin-right: 10px;
}

.saveBtn {
  margin-left: 20px;
  width: 100px;
  height: 50px;
  padding: 10px !important;
}
.buttonStyle {
  margin-bottom: 10px !important;
}
.toolTool {
  &:hover {
    span {
      display: block;
    }
  }
  span {
    display: none;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    z-index: 9998;
    position: absolute;
    margin-top: 20px;
    padding: 10px;
    overflow-wrap: anywhere;
    // width: 550px;
    border-radius: 6px;
    border: 1px solid rgb(230, 231, 232);
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 19px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: rgb(230, 231, 232);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 20px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-bottom-color: white;
    }
  }
}
