.spinner {
  /* Spinner size and color */
  width: 1.5rem;
  height: 1.5rem;
  border-top-color: #444;
  border-left-color: #444;
  /* Additional spinner styles */
  animation: spinner 400ms linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}
/* Animation styles */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Optional — create your own variations! */
.spinnerLarge {
  width: 5rem;
  height: 5rem;
  border-width: 5px;
}
.spinnerSlow {
  animation: spinner 1s linear infinite;
}
.spinnerBlue {
  border-top-color: #223149;
  border-left-color: #f58020;
}
.loaderClass {
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  background-color: transparent;
  opacity: 1;
  z-index: 100000;
}
