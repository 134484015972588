@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.bgColor {
  background-color: #fffafc;
  min-height: calc(100vh - 84px);
}
.innerBg {
  background-color: #ffffff !important;
  padding-top: 40px;
}
.borderClass {
  border-bottom: 1px solid #c7c8ca;
  .main {
    max-width: 490px;
    overflow-y: auto;
    width: 100%;
  }
  .flexClass {
    display: flex;
    align-items: center;
    margin-top: 45px;
    width: 490px;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      margin-right: 20px;
      color: #c7c8ca;
      cursor: pointer;
      border-bottom: 1px solid transparent;
      padding-bottom: 5px;
      padding: 0px 10px;
    }
  }
}
.activeClass {
  color: #a93e71 !important;
  border-bottom: 1px solid #a93e71 !important;
  font-weight: 600 !important;
}
