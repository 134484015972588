@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.main {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  border-radius: 8px;
  padding: 16px;
  .updatedDiv {
    background-color: #363636;
  }
  .innerFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $sm) {
    }
    .inner {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 25px;
      p {
        letter-spacing: 0.17px;
        color: #696f7a;
        font-weight: 400;
        font-size: 14px;
        span {
          color: #696f7a;
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.17px;
      color: #363636;
    }
    .icons {
      display: flex;
      align-items: center;
      @media (max-width: $sm) {
        margin-top: 10px;
      }
      img {
        width: 22px;
        height: 22px;
        margin-left: 15px;
        cursor: pointer;
      }
      p {
        text-align: end;
      }
    }
    .textSalesforce {
      display: flex;
      align-items: center;
      min-width: 180px;
      @media (max-width: $sm) {
        margin-top: 10px;
      }
      @media (max-width: $xs) {
        min-width: 100px;
      }
      p {
        text-align: end;
        height: 22px;
      }
    }
  }
}
.divElipsis {
  div {
    white-space: nowrap;
    width: 300px !important;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}

.rounded {
  cursor: pointer;
  position: relative;
  &:hover > div {
    display: block;
    width: fit-content;
    max-width: 300px;
  }
}
.tooltipText {
  font-weight: 400;
  font-size: 14px;
  color: #696f7a !important;
  overflow-wrap: break-word;
  text-align: center;
}
.tooltipChild {
  width: 100%;
  height: 100%;
}
.tooltip {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: fit-content;
  position: absolute;
  top: 150%;
  margin-top: 40px;
  padding: 10px;
  display: none;
  left: -10px;
  padding-right: 15px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

  &::before {
    content: '';
    top: -33px;
    left: 10px;
    z-index: 2010;
    clip-path: polygon(50% 61%, 0% 100%, 100% 100%);
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    height: 35px;
    width: 30px;
    position: absolute;
  }
}
