@import '../../../styles/breakpoints';

.wraper {
  @media (max-width: $md) {
    display: block;
  }
  display: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 10px 20px;
  position: fixed;
  right: 0;
  z-index: 100000;
  top: 50px;
  background-color: white;
  height: 100vh;
  width: 250px;
  .innerDiv {
    margin: 20px 20px;
    display: flex;
    position: relative;

    padding-bottom: 5px;
  }
  .menuSectionIcon {
    margin-right: 10px;
    width: 24px;
  }

  .menuItem {
    border-radius: 10px;

    .menuLink {
      text-decoration: none;
      color: #304059;

      .icon {
        right: 0;
        top: 50%;
        position: absolute;
      }
    }
    .submenu {
      overflow-y: hidden;
      width: 100%;
      border-radius: 0 0 10px 10px;
    }
  }
}

.backdropDiv {
  position: fixed;
  z-index: -1;
  inset: 0;
  background: transparent;
}
