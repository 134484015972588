.mainDiv {
  max-width: 310px !important;
  padding: 7px 0px;
  .modalTitle {
    font-weight: 600;
    font-size: 18px;
    color: #673e9b;
    text-align: center;
  }
  .modalText {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
  }
  .iconRefresh {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    img {
      width: 32px;
      height: 40px;
      margin-bottom: 16px;
    }
  }
  .p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #363636;
  }

  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .btnClassModal {
    height: 50px !important;
    width: 100%;
    background-color: #673e9b;
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    padding: 10px !important;
    span {
      color: #673e9b !important;
    }
  }
}
