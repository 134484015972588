@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';
.wraper {
  margin-top: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  .body {
    width: 100%;
    display: flex;
    @media (max-width: $xs) {
      flex-wrap: wrap;
    }
    @media (max-width: $sm) {
      flex-wrap: wrap;
    }

    .getInfo {
      width: 40%;
      @media (max-width: $xs) {
        width: 100% !important;
      }
      @media (max-width: $sm) {
        width: 100% !important;
      }
    }
    .feedbackSection {
      flex: 1;
      margin-left: 40px;
      @media (max-width: $xs) {
        margin: 10px 0px !important;
      }
      @media (max-width: $sm) {
        margin: 10px 0px !important;
      }
    }
    .datesPickerLower {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      margin-top: 45px;
      @media (max-width: $xs) {
        grid-template-columns: 1fr !important;
      }
      @media (max-width: $sm) {
        grid-template-columns: 1fr !important;
      }
    }
    .datesPicker {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      @media (max-width: $xs) {
        grid-template-columns: 1fr !important;
      }
      @media (max-width: $sm) {
        grid-template-columns: 1fr !important;
      }
    }
    .btnSection {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      .btnClass {
        height: 50px !important;
        margin-left: 20px;
      }
    }
  }
  .btnSection {
    margin-top: 20px;
    display: flex;
    img {
      cursor: pointer;
    }
    justify-content: flex-end;
    .btnClass {
      height: 50px !important;
      margin-left: 20px;
    }
  }
}

.zDynamic {
  z-index: 3000 !important;
}
.zDynamicState {
  z-index: 4000 !important;
}
.zDynamicState2 {
  z-index: 4001 !important;
}
.lbl {
  @include font29;
  @include p-small;
  color: $neutral1;
  font-family: 'Montserrat';
  font-style: normal;
  margin-bottom: 5px;
  text-transform: capitalize;
  @media (max-width: $md) {
    margin-bottom: 8px;
  }
  @media (max-width: $xxs) {
    margin-bottom: 5px;
  }
}
