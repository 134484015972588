.table {
  width: 100%;
  padding: 10px 10px;

  ::-webkit-scrollbar {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #c7c8ca;
    border-radius: 8px;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: none;
  }
}
.tableWidth {
  max-width: 1920px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #c7c8ca;
    border-radius: 8px;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: block !important;
  }
}
.th {
  padding: 15px 10px;
  text-align: left !important;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: #363636;
  background-color: #fcfaff;
}
.tableRow {
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

  &:hover {
    td {
      background-color: #f5f5f5 !important;
    }
  }
}
.checkboxContainer {
  display: inline;
  padding-left: 20px;
}

.genericTableOnLoading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #363636;
  z-index: 4;
  opacity: 0.1;
}
.genericTableOffLoading {
  display: none;
}
