.main {
  display: flex;
  .image {
    height: 70px;
    width: 70px;
    display: flex;
    border-radius: 70px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      border-radius: 100px;
    }
  }
  .initialsDiv {
    color: white;
    background-color: grey;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: inherit;
  }
}
