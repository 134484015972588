@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.mainEdit {
  background: #ffffff;
  padding: 24px 16px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  .innerDiv {
    @include gridFive(20, 40);
    @media (max-width: $lg) {
      grid-template-columns: repeat(5, 1fr);
    }
    .camDiv {
      display: flex;
      align-items: center;
      width: 100%;
      & div {
        width: 100%;
      }
      img {
        margin-right: 10px;
      }
    }
  }
  .btnSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    .img {
      margin-right: 20px;
      cursor: pointer;
    }
    .btnClass {
      background-color: #304059;
    }
  }
}

.switch {
  margin-top: 17px;
}
