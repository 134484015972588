@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.overviewWraper {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  border-radius: 8px;
  padding: 20px 20px 20px 20px;
  margin-top: 15px;
  @include gridThree(20, 20);
  span {
    font-weight: 600;
    margin-top: 5px;
  }
  p {
    font-weight: 400;
  }
}
.heading {
  font-weight: 600;
  font-size: 18px;
  color: #673e9b;
  margin-top: 35px;
}

.zDynamicState4 {
  z-index: 400 !important;
}

.popper {
  transform: translate(-110px, 50px) !important;
}
