.container {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: #757575;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkMark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid rgba(103, 103, 103, 0.6);
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkMark {
  background-color: transparent;
}

/* Create the checkMark/indicator (hidden when not checked) */
.checkMark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkMark when checked */
.container input:checked ~ .checkMark:after {
  display: block;
}

/* Style the checkMark/indicator */
.container .checkMark:after {
  left: 6px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid rgba(103, 103, 103, 0.6);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
