@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.mainEdit {
  background: #ffffff;
  padding: 13px 10px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  border-radius: 8px;
  margin: 15px 0px;
  margin-bottom: 40px;

  .innerDiv {
    @include gridEight(20, 40);
    @media (max-width: $lg) {
      grid-template-columns: repeat(5, 1fr);
    }
    .camDiv {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
  .btnSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    .img {
      margin-right: 20px;
      cursor: pointer;
    }
    .btnClass {
      background-color: #304059;
    }
  }
}

.switch {
  margin-top: 17px;
}
.loadingDiv {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading {
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid transparent;
    border-bottom: 2px solid black;
    width: 100px;
    height: 100px;
  }
}
.zDynamic {
  z-index: 3000 !important;
  @media (max-width: $lg) {
    z-index: 100 !important;
  }
}
