@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.colrBg {
  background-color: red !important;
}

.wraper {
  width: 100%;
  .lbl {
    @include font29;
    @include p-small;
    color: $neutral1;
    font-family: 'Montserrat';
    font-style: normal;
    margin-bottom: 5px;
    text-transform: capitalize;
    @media (max-width: $md) {
      margin-bottom: 8px;
    }
    @media (max-width: $xxs) {
      margin-bottom: 5px;
    }
  }
  .selectClass {
    position: relative;
    cursor: pointer;

    & option {
      background-color: red;
      z-index: 5;
    }
  }
  .selectBox {
    width: 100%;
    margin-top: 4px;
    position: relative;
    border-radius: 5px;
    height: fit-content;
    cursor: pointer;
  }
  .displayValueOnly {
    padding: 10px 5px;
    color: rgba($color: #000000, $alpha: 0.7);
    font-size: 14px;
    font-weight: 400;
  }
}
.errorMessage {
  display: block;
  font-size: 12px;
  margin-left: 5px;
  color: #ff5050;
  font-weight: 300 !important;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    input {
      height: 100%;
    }
  }
}

.css-1hb7zxy-IndicatorsContainer {
  align-items: flex-start !important;
  margin-top: 10px;
}

.tooltipText {
  font-weight: 400;
  font-size: 14px;
  color: #696f7a;
  text-align: center;
}
.tooltipChild {
  width: 100%;
  height: 100%;
}
.tooltip {
  background: white;
  border-radius: 4px;
  z-index: 9998;
  width: fit-content;
  max-width: 100%;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid rgb(230, 231, 232);
  word-wrap: break-word;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 19px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: rgb(230, 231, 232);
  }

  &::after {
    content: '';
    display: block;
    color: #ff5050;
    position: absolute;
    left: 20px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
  }
}
