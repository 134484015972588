.table {
  width: 100%;
  padding: 0px 10px;
  border-spacing: 0px 0px !important;

  ::-webkit-scrollbar {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #c7c8ca;
    border-radius: 8px;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: none;
  }
  tbody {
    tr {
      border-radius: 0px !important;
      height: 40px !important;
      font-size: 14px;
    }
    // padding-bottom: 10px !important;
  }
}
.errorTable {
  width: 100%;
  padding: 0px 10px;
  ::-webkit-scrollbar {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #c7c8ca;
    border-radius: 8px;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: none;
  }
  tbody {
    tr {
      height: 90px !important;
    }
  }
}

.tableWidth {
  max-width: 1920px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #c7c8ca;
    border-radius: 8px;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: block !important;
  }
}
.th {
  padding: 15px 10px;
  text-align: left !important;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: #363636;
  background-color: transparent;

  min-width: 180px;
  height: 50px !important;
}
.thead {
  top: -15px !important;
  background-color: #f8fffd !important;
}

.tableRow {
  // box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  &:first-child {
    td {
      &:first-child {
        border-radius: 5px 0px 0px 0px !important;
      }
      &:last-child {
        border-radius: 0px 5px 0px 0px !important;
      }
    }
  }
  &:last-child {
    td {
      &:first-child {
        border-radius: 0px 0px 0px 5px !important;
      }
      &:last-child {
        border-radius: 0px 0px 5px 0px !important;
      }
      border-bottom: 1px solid #e6e7e8 !important;
    }
  }

  td {
    border-top: 1px solid #e6e7e8 !important;
    border-radius: 0px !important;
    &:first-child {
      border-left: 1px solid #e6e7e8 !important;
    }
    &:last-child {
      border-right: 1px solid #e6e7e8 !important;
    }
  }

  &:hover {
    td {
      background-color: #fcfaff !important;
      cursor: pointer;
    }
  }
}
.checkboxContainer {
  display: inline;
  padding-left: 20px;
}

.genericTableOnLoading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #363636;
  z-index: 4;
  opacity: 0.1;
}
.genericTableOffLoading {
  display: none;
}
