@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.gridClass {
  @media (max-width: $lg) {
    grid-template-columns: 0.1fr repeat(6, 1fr) !important;
  }

  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
.respBtnClass {
  @media (max-width: $lg) {
    display: flex !important;
    justify-content: flex-start !important;
  }

  @media (max-width: $md) {
    display: flex !important;
    justify-content: flex-start !important;
  }

  @media (max-width: $sm) {
    display: flex !important;
    justify-content: flex-start !important;
  }
}

.mainTable {
  padding-bottom: 50px;
  width: calc(1740px - 28px);

  .tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 10px;
    position: sticky;
    top: -10px;
    background-color: #f6fafd;
    z-index: 200;
    p {
      font-weight: 600;
      font-size: 14px;
      color: #363636;
      cursor: pointer;
      flex-basis: 300px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }
  .rows {
    width: 100%;
    display: flex;
    align-items: center;
    // background: #ffffff;
    // box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    // border-radius: 8px;
    background: #ffffff;
    border-bottom: 1px solid #e6e7e8;
    padding: 6px 10px;
    &:first-child {
      border-radius: 5px 5px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 0px 5px 5px;
    }
    .imgDiv {
      display: flex;
      align-items: center;
      flex-basis: 300px;
      margin-right: 10px;
      margin-left: 0px;
      position: relative;
      &:hover {
        span {
          display: block;
        }
      }
      .avatar {
        cursor: pointer;
        margin: 0px;
      }
      span {
        font-weight: 400;
        font-size: 14px;
        color: #696f7a;
        display: none;
        background: white;
        border-radius: 4px;
        z-index: 9998;
        width: fit-content;
        max-width: 100%;
        position: absolute;
        top: 50%;
        margin-top: 20px;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid rgb(230, 231, 232);
        word-wrap: break-word;
        box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
        left: 50px;
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 19px;
          bottom: 100%;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: rgb(230, 231, 232);
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 20px;
          bottom: 100%;
          width: 0;
          height: 0;
          border: 9px solid transparent;
          border-bottom-color: white;
        }
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.17px;
      color: #363636;
      flex-basis: 300px;
      margin-right: 10px;
      overflow-wrap: anywhere;
      min-width: 100px;
    }
    .toolTool {
      width: 310px;
      cursor: pointer;
      position: relative;
      &:hover {
        span {
          display: block;
        }
      }
      span {
        display: none;
        background: white;
        border-radius: 4px;
        z-index: 9998;
        // width: 300px;
        max-width: 300px;
        position: absolute;
        top: 50%;
        margin-top: 20px;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid rgb(230, 231, 232);
        word-wrap: break-word;
        box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
        font-weight: 400;
        font-size: 14px;
        color: #696f7a;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 19px;
          bottom: 100%;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: rgb(230, 231, 232);
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 20px;
          bottom: 100%;
          width: 0;
          height: 0;
          border: 9px solid transparent;
          border-bottom-color: white;
        }
      }
    }
  }
}

.maxWidthClass {
  max-width: 1850px;
  overflow-x: auto;
  max-height: calc(100vh - 340px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px !important; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px !important;
    border-radius: 8px;
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: #c7c8ca;
    border-radius: 8px;
  }

  &::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: block !important;
  }
}

.flexSwitch {
  flex-basis: 250px;
  margin-right: 10px;
}
.emptyDiv {
  flex-basis: 150px !important;
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
    margin-left: 10px;
  }
}

.rowsBody {
}

.modal {
  max-width: 300px !important ;
  & div {
    margin-top: 0px !important;
  }
  p {
    color: #363636 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  h6 {
    text-align: center !important;
  }
}

.pTagAvatar {
  margin-left: 16px;
  max-width: 50px;
  flex-basis: 250px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}
.pTag {
  flex-basis: 300px !important;
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}
.pTagEmail {
  flex-basis: 450px !important;
  overflow: hidden;
  width: 280px;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.morePadding {
  margin-bottom: 100px;
}

.innerHeight {
  border: 1px solid #e6e7e8;
  border-radius: 5px;
}
