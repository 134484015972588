@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.mainCard {
  background: #ffffff;
  border-radius: 8px;
  height: 100%;
  padding: 24px 16px;
  &:hover {
    box-shadow: 0px 4px 16px rgba(198, 200, 203, 0.5);
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      cursor: pointer;
    }
  }
  .heading {
    font-weight: 600;
    font-size: 20px;
    color: #363636 !important;
    text-transform: capitalize;
  }
  .headerDiv {
    padding-bottom: 16px;
    border-bottom: 1px solid #9da1aa;
    .para {
      font-weight: 500;
      font-size: 14px;
      color: #696f7a;
    }
  }
  .tableHead {
    @include gridTwo(10, 3);
    margin-top: 32px;
    @media (max-width: $lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    .header {
      font-weight: 600;
      font-size: 14px;
      color: #363636;
    }
  }
  .tableBody {
    @include gridTwo(10, 3);
    margin-top: 45px;
    @media (max-width: $lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    ul {
      list-style: none;
      li {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        color: #363636;
        padding-left: 5px;
        p {
          margin-right: 10px;
          font-weight: 600;
        }
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      color: #363636;
    }
  }
}
.verticalScroll {
  max-height: 340px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px !important; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px !important;
    border-radius: 8px;
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: #c7c8ca;
    border-radius: 8px;
  }

  &::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: block !important;
  }
}

.rounded {
  cursor: pointer;
  position: relative;
  &:hover > div {
    display: block;
  }
}
.tooltipText {
  font-weight: 400;
  font-size: 14px;
  color: #696f7a;
  text-align: center;
  white-space: nowrap;
  word-break: keep-all;
  span {
    font-weight: bold;
    white-space: nowrap;
    word-break: keep-all;
  }
}
.tooltipChild {
  width: 100%;
  height: 100%;
}
.tooltip {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: fit-content;
  position: absolute;
  top: 100%;
  margin: 20px 0;
  padding: 10px;
  display: none;
  left: -120px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
  &::before {
    content: '';
    top: -32px;
    z-index: 2010;
    clip-path: polygon(50% 61%, 0% 100%, 100% 100%);
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    height: 35px;
    width: 30px;
    position: absolute;
  }
}
.tooltipLast {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: fit-content;
  position: absolute;
  top: -82px;
  padding: 10px;
  display: none;
  left: -120px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
}
.emptySpan {
  color: #363636 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
