.main {
  width: 100%;
  position: relative;
  .label {
    margin-bottom: 13px;
    text-transform: capitalize;
    color: #363636;
    font-weight: 400;
    font-size: 14px;
  }
  & .inpDiv {
    background: #ffffff;
    border-radius: 6px;
    padding-left: 10px;
    margin-top: 6px;

    font-weight: 500;
    height: 41px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    .datePicker {
      width: 100%;
      height: 60px;
      color: #333333;
      cursor: pointer;
      border: none;
      position: relative;
      background-color: transparent;
      font-weight: 600;
      font-size: 14px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #9da1aa;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
.icon {
  cursor: pointer;
  right: 18px;
  display: flex;
}
.errorMessage {
  display: block;
  font-size: 12px;
  margin-left: 5px;
  color: #ff5050;
}

.iconsDiv {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0px 16px;
  .p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.12px;
    color: var(--primary-color);
    cursor: default;
  }
  button {
    border: none;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    background-color: transparent;
  }
}
