@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.mainLoader {
  top: 0;
  left: 0;
  width: '100%';
  height: '100%';
  z-index: 50000;
  position: 'fixed';
  background: '#F8FFFD';
}

.btnClass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 10px;
  @media (max-width: 820px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .all {
    font-weight: 600;
    font-size: 20px;
    color: #363636;
  }
  .btn {
    display: flex;
    align-items: center;
    @media (max-width: 820px) {
      justify-content: flex-end;
      width: 100%;
      flex-wrap: wrap;
    }
    button {
      background-color: transparent;
      border: 1px solid #a93e71;
      margin-left: 15px;
      @media (max-width: 820px) {
      }
      span {
        color: #a93e71;
      }
    }
    .btnClass {
      background-color: #a93e71 !important;
      margin-top: 0px;
      margin-bottom: 0px;
      span {
        color: #ffffff !important;
      }
    }
    .defaultClass {
      background-color: #a93e71 !important;
      margin-top: 0px;
      margin-bottom: 0px;
      span {
        color: #ffffff !important;
      }
    }
  }
}

.innerSecondDiv {
  margin-top: 36px;
  border-bottom: 1px solid #f7f9fc;
  border-top: 1px solid #f7f9fc;
}

.ml-1 {
  margin-left: 10px;
}

.rounded {
  cursor: pointer;
  position: relative;
  &:hover > div {
    display: block;
    width: max-content;
  }
}
.tooltipText {
  font-weight: 400;
  font-size: 14px;
  color: #696f7a;
  text-align: center;
}
.tooltipChild {
  width: 100%;
  height: 100%;
}
.tooltip {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: fit-content;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  padding: 10px;
  display: none;
  left: -10px;
  padding-right: 15px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

  &::before {
    content: '';
    top: -33px;
    left: 10px;
    z-index: 2010;
    clip-path: polygon(50% 61%, 0% 100%, 100% 100%);
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    height: 35px;
    width: 30px;
    position: absolute;
  }
}
.imgDel {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.colorText {
  color: #a93e71 !important;
}
.btnYes {
  background-color: #a93e71 !important;
}
.btnNo {
  span {
    color: #a93e71 !important;
  }
}

.attorneyColumnEllipsis {
  div {
    white-space: nowrap;
    width: 240px !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}

.imgDiv {
  display: flex;
  align-items: center;
  flex-basis: 300px;
  margin-right: 10px;
  margin-left: 0px;
  .avatar {
    cursor: pointer;
    margin: 0px;
  }
}

.avatar {
  cursor: pointer;
  margin: 0px;
}

.userName {
  margin-left: 16px;
}
.tblBody {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #ffffff;
  padding: 17px 10px;
  border-radius: 5px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  cursor: pointer;
}
.tableDiv {
  height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
}

.bgColor {
  background-color: #fffafc !important;
}
.active {
  background: #a93e71 !important;
}
.numberClass {
  color: #a93e71 !important;
}
.inactiveClass:hover {
  background-color: #fffafc !important;
  p {
    color: #a93e71 !important;
  }
}

.openPositions {
  background-color: red;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  // padding: 5px 6px;
  color: #ffffff;
  font-weight: bold;
  margin-left: -13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.openPositionsZero {
  // background-color: red;
  width: 30px;
  height: 30px;
  margin-left: -13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noMatch {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  span {
    font-weight: bold;
    margin-right: 10px;
  }
  button {
    height: auto;
    padding: 6px 8px;
    border: 1px solid #a93e71;
    background-color: transparent;

    span {
      margin: 0;
      color: #a93e71 !important;
    }
  }
}

.btnDropdown {
  border: 1px solid #a93e71 !important;
  span {
    color: #a93e71 !important;
  }
}

.chooseColumnBtn {
  border: 1px solid #a93e71 !important;
  span {
    color: #a93e71 !important;
  }
  @media (max-width: 453px) {
    margin-top: 4px;
  }
}

.ar1 {
  padding-left: 55px !important;
  justify-content: space-between !important;
}
.labelContainerClass {
  display: flex;
}
.mainElement {
  padding: 14px 20px;
}
