@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.wraper {
  margin-top: 30px;
  .tblHeader {
    display: grid;
    margin-bottom: 16px;
    margin-top: 48px;
    grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr;
  }
  .tblBody {
    &:last-child {
      .toolTool {
        span {
          // top: 0%;
          top: -100% !important;
          margin-top: 10px !important;
          &::before {
            content: '';
            display: none;
          }
          &::after {
            content: '';
            display: none;
          }
        }
      }
    }
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #ffffff;
    padding: 0px 17px;
    // border-radius: 5px;
    // margin-top: 10px;
    display: grid;
    height: 50px;
    font-size: 14px;
    border: 1px solid #e6e7e8 !important;
    border-radius: 5px 5px 0px 0px;
    grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr;
    @media (max-width: $md) {
      grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr;
    }
    @media (max-width: $xs) {
      grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr;
    }
  }
  .tblElements {
    @include tableHeader;
    color: #363636 !important;
  }
  .tblValues {
    @include tableHeader;
    font-weight: 400;
    display: grid;
    color: #363636 !important;
    align-content: center;
  }
  .btnWraper {
    height: 40px;
    margin-top: 20px;
  }
}

.scrollDiv {
  @media (max-width: 1500px) {
    overflow-x: scroll;
    max-width: 1500px;
    &::-webkit-scrollbar:horizontal {
      width: 5px; /* Remove scrollbar space */
      background: #e6e7e8; /* Optional: just make scrollbar invisible */
      height: 5px;
      border-radius: 8px;
      display: block !important;
    }
  }
  .innerScrollDiv {
    table-layout: fixed;
    min-width: 100%;
    display: table;
  }
}
.rowIcons {
  display: flex;
  justify-content: flex-end;
  img {
    cursor: pointer;
    margin-right: 20px;
    width: 20px;
    height: 20px;
  }
}
.modalHeader {
  text-align: center;
  .deleteText {
    @include normal-tag;
    line-height: 100%;
    color: $attorney1;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  p {
    @include p-small;
    padding: 0px 20px;
    color: $neutral1;
    line-height: 150%;
    margin-bottom: 24px;
  }
  button {
    width: 100%;
    margin-bottom: 30px;
  }
  .cancelText {
    @include tableHeader;
    color: $attorney1;
    cursor: pointer;
  }
}
.imgDiv {
  margin-right: 10px;
}

.dateHeader {
  padding-left: 23px !important;
}
.buttonStyle {
  margin-bottom: 10px !important;
}

.toolTool {
  &:hover {
    span {
      display: block;
    }
  }
  span {
    display: none;
    background: white;
    border-radius: 4px;
    z-index: 9998;
    width: fit-content;
    max-width: 100%;
    position: absolute;
    top: 50%;
    margin-top: 20px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid rgb(230, 231, 232);
    word-wrap: break-word;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 19px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: rgb(230, 231, 232);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 20px;
      bottom: 100%;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-bottom-color: white;
    }
  }
}
