@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.main {
  background-color: #fcfaff;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0px 45px 0px;

    .bgClass {
      border: 1px solid #673e9b;
      background-color: transparent;
    }
    .text {
      color: #673e9b;
    }
  }
  .innerFlex {
    display: flex;
    align-items: center;

    .bgDiv {
      padding: 17px 16px;
      background: #ffffff;
      box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      @media (max-width: $xs) {
        padding: 17px 5px;
      }
      .name {
        font-weight: 600;
        font-size: 14px;
        color: #667782;
        margin-top: 3px;
        span {
          font-weight: 500;
        }
      }
    }
    .arrow {
      transform: rotate(90deg);
      width: 28px;
      height: 15.27px;
      cursor: pointer;
    }
    .headingTitle {
      font-weight: 600;
      font-size: 32px;
      color: #304059;
      margin-bottom: 0px;
      margin-left: 20px;
      @media (max-width: $sm) {
        font-size: 20px;
      }
    }
  }
  .dateHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .datePicker {
      margin-left: 20px;
      @media (max-width: 900px) {
        max-width: 200px;
      }
    }
    .date {
      background: transparent !important;
    }
  }
}

.responsiveClass {
  @media (max-width: 900px) {
    justify-content: flex-end;
    width: 100%;
    align-items: flex-start;
    margin-top: 20px;
  }
}

.grid {
  margin: 28px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px 20px;
  .card {
    box-shadow: 0px 4px 16px rgba(198, 200, 203, 0.5);
    .verticalScroll {
      max-height: none;
      overflow-y: unset !important;
    }
  }
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
  button {
    padding: 10px 24px;
    max-width: 222px;
    width: 100%;
    height: 48px;
    background-color: transparent;
    border: 1px solid #223149;
    border-radius: 24px;
    span {
      color: #223149 !important;
    }
  }
}

.checkbox input:checked ~ .tick {
  background-color: #304059;
  border: none;
}

.checkbox .tick:after {
  left: 7px;
  top: 2px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
