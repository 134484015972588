.mainDiv {
  max-width: 380px !important;
  min-height: auto !important;
  .modalTitle {
    font-weight: 600;
    font-size: 18px;
    color: #673e9b;
    margin-bottom: 10px;
  }
  .upperDiv {
    height: 290px;
    overflow: auto;
    margin-top: 25px;

    &::-webkit-scrollbar {
      width: 5px !important;
      background: #e6e7e8 !important;
      border-radius: 8px !important;
      display: block !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #c7c8ca;
      border-radius: 8px;
    }

    .rowDiv {
      padding: 0px 20px;
      width: 100%;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffffff;
    }
  }
  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .btnClassModal {
    height: 40px !important;
    width: 200px;
    background-color: #673e9b;
    &:disabled {
      background-color: #9da1aa;
    }
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 180px;
    background-color: #fff;
    margin-top: 10px;
    span {
      color: #673e9b !important;
    }
  }
}

.checkbox {
  border: 1.5px solid #673e9b !important;
  &:after {
    left: 6px;
    top: 1px;
    width: 4px;
    height: 9px;
    border: solid #673e9b !important;
    border-width: 0 3px 3px 0 !important;
    transform: rotate(45deg);
  }
}

.crossImg {
  display: flex;
  justify-content: flex-end;
  img {
    cursor: pointer;
  }
}
.selectAllDiv {
  padding: 15px 20px;
  margin-top: 25px;
  background: #f6fafd;
  border-radius: 8px;
  .checkbox1 {
    border: 1.5px solid #673e9b !important;
    &:after {
      left: 6px;
      top: 1px;
      width: 4px;
      height: 9px;
      border: solid #673e9b;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  .checkboxClass {
    color: #363636;
    font-weight: 700;
  }
}

.modalBody {
  max-height: 100vh !important;
}

.btnReset {
  height: 40px !important;
  width: 180px;
  background-color: #fff;
  margin-top: 10px;
  span {
    white-space: nowrap;
    color: #673e9b !important;
  }
}
