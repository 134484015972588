@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.barStateMain {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px 0px 40px 0px;
  @media (max-width: $sm) {
    flex-direction: column;
    align-items: flex-start;
  }
  .textField {
    max-width: 315px;
    width: 100%;
    .field {
      img {
        height: 14px;
        width: 14px;
        top: 24px;
      }
    }
  }
  .btnDiv {
    max-width: 210px;
    width: 100%;
    @media (max-width: $sm) {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
      max-width: 100%;
    }
    button {
      max-width: 210px;
      width: 100%;
      height: 45px;
      @media (max-width: $sm) {
        max-width: 190px;
      }
    }
  }
}

.errorMessage {
  display: block;
  font-size: 12px;
  margin-left: 5px;
  color: #ff5050;
  margin-top: 10px;
}
.main {
  padding: 15px 0px;
  @media (max-width: $sm) {
    width: 768px;
  }
  .tableHeader {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 15px 16px 15px;
  }
  .card {
    margin-top: 12px;
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    border-radius: 8px;
    padding: 16px;
    .innerChild {
      display: flex;
      width: 100%;
      align-items: center;
      .field {
        margin-left: 190px;
        width: 100%;
        max-width: 250px;
      }
      input {
        margin-top: 0px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.17px;
        color: #363636;
      }
      img {
        cursor: pointer;
        height: 20px;
        width: 20px;
      }
    }
  }
}
.btn {
  background-color: #304059;
  max-width: 225px;
  width: 100%;
}

.maxWidthClass {
  @media (max-width: $sm) {
    max-width: 760px;
    overflow-x: auto;
  }
}

.weight {
  margin-left: -20px;
}

.emptyDiv {
  flex-basis: 50px;
  margin-right: 10px;
}

.practiceText1 {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: #363636;
  flex-basis: 200px;
}
.practiceText2 {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: #363636;
  flex-basis: 400px;
}

.imgDiv {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  img {
    margin-left: 10px;
  }
}

.btnYes {
  background-color: #39695b !important;
}
.btnNo {
  span {
    color: #39695b !important;
  }
}

.modal {
  max-width: 400px !important;
  .img {
    display: flex;
    justify-content: flex-end;
    img {
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
  p {
    text-align: center;
    color: #39695b !important;
  }
}

.mainBtnDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
.btnYes {
  height: 40px !important;
  width: 250px;
  background-color: #39695b !important;
  pointer-events: auto;
}

.disableClass {
  background-color: #9da1aa !important;
  height: 40px !important;
  width: 250px;
  pointer-events: none;
  cursor: default;
  span {
    color: #363636;
  }
}
.uploadFileDiv {
  margin: 20px 0px;
  border: 1px dashed var(--primary-color);
  border-radius: 8px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    height: 65px;
    width: 65px;
    cursor: pointer;
  }
  .green {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #363636 !important;
    margin-top: 15px;
  }
  .blue {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #2475d0 !important;
    margin-top: 5px;
  }
}
.borderDiv {
  margin-top: 15px;
  border: 1px solid var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 8px;
  img {
    cursor: pointer;
  }
}

.flex {
  display: flex;
  align-items: center;
  p {
    margin-left: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #363636 !important;
    text-align: left !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
  }
}

.progressWrapper {
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 0 20px;
  width: 100%;
  .progressbar {
    background: #e6e7e8;
    height: 5px;
    width: 100%;
    border-radius: 50px;
    overflow: hidden;
    .progress {
      background: var(--primary-color);
      height: 5px;
      border-radius: 50px;
      transition: all 0.3s;
    }
  }
}
