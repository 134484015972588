@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';
.wraper {
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  .editbarSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    @media (max-width: $xs) {
      grid-template-columns: 1fr;
    }
  }
  .btnSection {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    img {
      cursor: pointer;
    }
    .btnClass {
      height: 50px !important;
      margin-left: 20px;
    }
  }
}
.zDynamic {
  z-index: 3000 !important;
}
.zDynamic2 {
  z-index: 4000 !important;
}
.barEditFields {
  @media (max-width: $md) {
    margin-bottom: 15px;
  }
  @media (max-width: $xs) {
    margin-bottom: 15px;
  }
}
