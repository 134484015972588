@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.tooltipText {
  font-weight: 400;
  font-size: 14px;
  color: #696f7a;
  text-align: center;
}
.tooltipChild {
  width: 100%;
  height: 100%;
}
.tooltip {
  background: white;
  border-radius: 4px;
  z-index: 9998;
  width: fit-content;
  max-width: 100%;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid rgb(230, 231, 232);
  word-wrap: break-word;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 19px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: rgb(230, 231, 232);
  }

  &::after {
    content: '';
    display: block;
    color: #ff5050;
    position: absolute;
    left: 20px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
  }
}

.mainHeader {
  .innerFlex {
    display: flex;
    justify-content: space-between;
    @media (max-width: $xs) {
      flex-direction: column;
    }
    .attorney {
      font-weight: 600;
      font-size: 32px;
      color: #39695b;
    }
    .btns {
      display: flex;
      align-items: center;
      @media (max-width: $xs) {
        margin-top: 10px;
        justify-content: flex-end;
      }
      button {
        img {
          height: 15px;
          width: 15px;
        }
      }
      .btn {
        background-color: transparent;
        border: 1px solid #39695b;
        margin-right: 20px;
        span {
          color: #39695b;
        }
      }
      .btnClass {
        background-color: #39695b;
      }
      .btnClassRefresh {
        background-color: #39695b;
        margin-right: 20px;
        img {
          width: 30px;
          height: 30px;
          margin: 0px 0 0 5px;
        }
      }
    }
  }
  .grid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
    position: relative;
    .textField {
      flex: 1;
      margin-right: 20px;
      margin-top: 7px;
      min-width: 400px;
      width: 100%;
      @media (max-width: 1100px) {
        max-width: 400px;
        flex: none;
        min-width: auto;
      }
      @media (max-width: $sm) {
        max-width: 100%;
        margin-right: 0px;
        min-width: 100%;
      }
      .field {
        input {
          background-color: transparent !important;
        }
        img {
          height: 14px;
          width: 14px;
          top: 19px;
        }
      }
    }
    .datePicker {
      width: 100%;
      margin-top: 7px;
      max-width: 212px;
      margin-right: 20px;
      @media (max-width: $sm) {
        max-width: 100%;
        margin-right: 0px;
      }

      .date {
        margin-top: 7px !important;
        background-color: transparent !important;
      }
    }
    .statusBar {
      max-width: 220px;
      width: 100%;
      margin-right: 20px;
      margin-top: 7px;
      @media (max-width: $sm) {
        max-width: 100%;
        margin-right: 0px;
      }
    }
    .resetDiv {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: 30px;
      position: relative;
      img {
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
      .reset {
        font-weight: 600;
        font-size: 14px;
        color: #39695b;
        margin-left: 10px;
      }
    }
  }
}
.dynamicClass {
  min-height: 41px !important;
}

.zDynamicState4 {
  z-index: 400 !important;
}

.check {
  background-color: transparent !important;
  border: 1px solid #39695b !important;
  span {
    color: #39695b !important;
  }
}
.apply {
  background-color: #39695b !important;
}
.sync {
  background-color: #39695b !important;
}
.poperClassForPlacementOnMenuOpen {
  @media (max-width: 1656px) {
    left: -118px !important;
  }
  @media (max-width: 1400px) {
    left: 10px !important;
  }
}

.poperClassForPlacementOnMenuClosed {
  @media (max-width: 1397px) {
    left: -118px !important;
  }
  @media (max-width: 1171px) {
    left: 0px !important;
  }
}

.poperClassForLiStartDateOnMenuClosed {
  @media (max-width: 1175px) {
    left: -118px !important;
  }
  @media (max-width: 930px) {
    left: 0px !important;
  }
}
.poperClassForLiStartDateOnMenuOpen {
  @media (max-width: 1419px) {
    left: -118px !important;
  }
  @media (max-width: 1175px) {
    left: 0px !important;
  }
}
.hoverBtn {
  position: relative;
  &:hover {
    & > div {
      display: block;
    }
  }
}
.tooltip {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: max-content !important;
  max-width: 300px !important;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  padding: 10px;
  display: none;
  padding-right: 15px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
}
