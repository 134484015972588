@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.form {
  padding: 40px 11px;
}

.mainBtnDiv {
  margin-top: 20px;
}
