@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.wrapper {
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: $sm) {
    margin-top: 20px;
  }
}

.mainDiv {
  gap: 20px;
  border-radius: 5px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: $sm) {
    display: flex;
    flex-direction: column;
  }
  .p1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #363636;
  }
  .p2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #363636;
  }
}
.btnDiv {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  img {
    cursor: pointer;
  }
}
.btnClass {
  height: 50px !important;
  margin-left: 20px;
}
.phoneLabel {
  @include font29;
  @include p-small;
  color: $neutral1;
  font-family: 'Montserrat';
  font-style: normal;
  margin-bottom: 5px;
  text-transform: capitalize;
  @media (max-width: $md) {
    margin-bottom: 8px;
  }
  @media (max-width: $xxs) {
    margin-bottom: 5px;
  }
}
