@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';
.wraper {
  margin-top: 20px;
  margin-bottom: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  .sectionOne {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    @media (max-width: $md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: $xs) {
      grid-template-columns: 1fr;
    }
  }
  .sectionTwo {
    display: grid;
    grid-gap: 20px;
    margin-top: 10px;
    grid-template-columns: 2fr 2fr 2fr;
    @media (max-width: $md) {
      grid-template-columns: 1fr;
    }
  }
  .sectionTwoAttorney {
    display: grid;
    grid-gap: 20px;
    margin-top: 10px;
    grid-template-columns: 2fr;
    @media (max-width: $md) {
      grid-template-columns: 1fr;
    }
  }
  .btnSection {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    img {
      cursor: pointer;
    }
    .btnClass {
      height: 50px !important;
      margin-left: 20px;
    }
  }
}
.zDynamic {
  z-index: 3000 !important;
}
.zDynamic2 {
  z-index: 4000 !important;
}
.zDynamic3 {
  z-index: 5000 !important;
}
.trainingEditFields {
  margin-bottom: 15px;
  @media (max-width: $md) {
    margin-bottom: 15px;
  }
  @media (max-width: $xs) {
    margin-bottom: 15px;
  }
}
.cleSectionAttorney {
  margin-top: 10px;
  display: grid;
  width: 100%;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.gridCol {
  grid-column: 1/-1;
}
