@import './_breakpoints.scss';
//form Grid

@mixin gridTwo($gap_y: 0, $gap_x: 0) {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;
  @media (max-width: $lg) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $md) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin gridThree($gap_y: 0, $gap_x: 0) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;
  @media (max-width: $lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: $xs) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xxs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin gridFour($gap_y: 0, $gap_x: 0) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;

  @media (max-width: $lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: $xs) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xxs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin gridFive($gap_y: 0, $gap_x: 0) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;

  @media (max-width: $lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: $xs) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xxs) {
    grid-template-columns: repeat(1, 1fr);
  }
}
@mixin gridSix($gap_y: 0, $gap_x: 0) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;

  @media (max-width: $lg) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: $md) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: $sm) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: $xs) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xxs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@mixin gridEight($gap_y: 0, $gap_x: 0) {
  display: grid;
  width: 100%;
  grid-template-columns: 0.3fr repeat(6, 1fr);
  grid-gap: $gap_y + px $gap_x + px;
  grid-auto-rows: auto;

  @media (max-width: $lg) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: $md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: $sm) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: $xs) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: $xxs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

// font-size
@mixin font40 {
  font-weight: 700;
  font-size: calc(14px + (40 - 14) * (100vw - 280px) / (2560 - 280));
}

// font-size 36px
@mixin font36 {
  font-weight: 600;
  font-size: calc(12px + (36 - 12) * (100vw - 280px) / (2560 - 280));
}

// font-size 32px
@mixin font32 {
  font-weight: 500;
  font-size: calc(10px + (32 - 10) * (100vw - 280px) / (2560 - 280));
}

// font-size 29px
@mixin font29 {
  font-weight: 400;
  font-size: calc(10px + (28 - 10) * (100vw - 280px) / (2560 - 280));
}

// font-size 24px
@mixin font24 {
  font-weight: 500;
  font-size: calc(12px + (24 - 12) * (100vw - 280px) / (2560 - 280));
}

// font-size 20px
@mixin font20 {
  font-weight: 500;
  font-size: calc(12px + (20 - 12) * (100vw - 280px) / (2560 - 280));
}

// font-size 18px
@mixin font18 {
  font-weight: 500;
  font-size: calc(10px + (18 - 10) * (100vw - 280px) / (2560 - 280));
}

// font-size 16px
@mixin font16 {
  font-weight: 500;
  font-size: calc(10px + (16 - 10) * (100vw - 280px) / (2560 - 280));
}

// font-size 14px
@mixin font14 {
  font-weight: 500;
  font-size: calc(10px + (14 - 10) * (100vw - 280px) / (2560 - 280));
}

@mixin heading1 {
  font-family: 'Montserrat';
  font-size: 40px;
  font-weight: 400;
}
@mixin heading2 {
  font-family: 'Montserrat';
  font-size: 32px;
  font-weight: 400;
}
@mixin heading3 {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 400;
}
@mixin header {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

@mixin p-small {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
@mixin small-text {
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
}
@mixin normal-tag {
  font-family: 'Montserrat';
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
}
@mixin labelTag {
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
@mixin tableHeader {
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: 20.02px;
  font-weight: 600;
}
