@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.wrapper {
  margin-top: 32px;
  padding-bottom: 30px;
}
.topDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  @media (max-width: 700px) {
    align-items: flex-start;
    flex-direction: column;
  }
  @media (max-width: 400px) {
    flex-direction: column;
  }
  p {
    font-family: 'Montserrat';
    font-size: 18px;
    color: #a93e71;
    font-weight: 600;
    width: 200px;
  }
}
.buttons {
  display: flex;
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    margin-top: 20px;
  }
}
.btnClassUncheck {
  height: 50px !important;
  width: 220px;
  margin-left: 20px;
  background-color: transparent;
  border-width: 3;
  border: 1px solid #a93e71;
  span {
    color: #a93e71 !important;
  }
  @media (max-width: 700px) {
    margin-bottom: 20px;
  }
}
.dataView2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 9px;
  margin-bottom: 33px;
  .dataView {
    margin: 15px 0px 0px 15px;
    padding: 15px;
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    border-radius: 8px;
    .text {
      font-weight: 600;
      font-size: 14px;
      color: #667782;
      text-align: center;
    }
  }
}
.innerMaxWidth {
  max-width: 100%;
  min-width: 1500px;
  overflow-x: scroll;
  @media (max-width: 1500px) {
    // &::-webkit-scrollbar:horizontal {
    //   width: 5px; /* Remove scrollbar space */
    //   background: #e6e7e8; /* Optional: just make scrollbar invisible */
    //   height: 5px;
    //   border-radius: 8px;
    //   display: block !important;
    // }
  }
}
.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 10px;
  position: sticky;
  top: 0;
  p {
    font-weight: 600;
    font-size: 14px;
    color: #363636;
    flex-basis: 200px;
    margin-right: 10px;
  }
  .emptyDiv {
    flex-basis: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    img {
      cursor: pointer;
      margin-left: 10px;
    }
  }
}
.rowsBody {
  max-height: 410px;
  @media (max-width: 1500px) {
    max-height: 850px;
  }
  overflow-y: scroll;
  // &::-webkit-scrollbar:horizontal {
  //   width: 5px; /* Remove scrollbar space */
  //   background: #e6e7e8; /* Optional: just make scrollbar invisible */
  //   height: 5px;
  //   border-radius: 8px;
  //   display: block !important;
  // }
  .innerHeight {
    // height: 410px;
    border-radius: 8px;
    border: 1px solid #e6e7e8;
    background: #fff;
    // @media (max-width: 1500px) {
    //   height: 530px;
    // }
  }
  .rows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    position: relative;
    width: 100%;
    // background: #f6fafd;
    // box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    // border-radius: 8px;
    // margin-bottom: 15px;
    border-bottom: 1px solid #e6e7e8;

    &:last-child {
      border-bottom: none;
    }

    .groupText {
      font-weight: 400;
      font-size: 14px;
      color: #363636;
      flex-basis: 195px;
      margin-right: 10px;
    }
    .groupTextCentered {
      font-weight: 400;
      font-size: 14px;
      color: #363636;
      flex-basis: 200px;
      margin-right: 10px;
      text-align: center;
    }
    .delView {
      flex-basis: 100px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
    .imgDiv {
      display: flex;
      align-items: center;
      flex-basis: 20px;
      margin-right: 7px;
      margin-left: 0px;
      .upIcon {
        margin-right: 15px;
      }
    }
    .toolTool {
      &:hover {
        span {
          display: block;
        }
      }
      span {
        display: none;
        background: white;
        border-radius: 4px;
        z-index: 9998;
        width: fit-content;
        max-width: 100%;
        position: absolute;
        top: 50%;
        margin-top: 20px;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid rgb(230, 231, 232);
        word-wrap: break-word;
        box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
        font-weight: 400;
        font-size: 14px;
        color: #696f7a;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 19px;
          bottom: 100%;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-bottom-color: rgb(230, 231, 232);
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 20px;
          bottom: 100%;
          width: 0;
          height: 0;
          border: 9px solid transparent;
          border-bottom-color: white;
        }
      }
    }
    &:last-child {
      .toolTool {
        span {
          top: -100%;
          &::before {
            content: '';
            display: none;
          }

          &::after {
            content: '';
            display: none;
          }
        }
      }
    }
  }
}
.btnDelete {
  background-color: #a93e71 !important;
}
.noDelete {
  background-color: #a93e71 !important;
}
.deleteClientDiv {
  display: flex;
  .deleteClient {
    height: 50px !important;
    width: 210px;
    background-color: #fff;
    border-width: 3;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ce4343;
    span {
      color: #ce4343 !important;
    }
    @media (max-width: 700px) {
      margin-bottom: 20px;
    }
  }
}
.modal {
  max-width: 300px !important ;
  max-height: 280px !important;
  & div {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
  }
  p {
    color: #363636 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  h6 {
    text-align: center !important;
  }
}
.maxWidthDiv {
  max-width: 1920px;
  overflow-x: auto;
  .innerMaxWidth {
    width: 1930px;
  }
}
.modalHeader {
  text-align: center;
  .deleteText {
    @include normal-tag;

    line-height: 100%;
    color: $client1;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  p {
    @include p-small;
    padding: 0px 20px;
    color: $neutral1;
    line-height: 150%;
    margin-bottom: 24px;
  }
  button {
    width: 100%;
    margin-bottom: 30px;
    background-color: $client1;
  }
  .cancelText {
    @include tableHeader;
    color: $client1;
    cursor: pointer;
  }
}

.openPositions {
  background-color: red;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* padding: 5px 6px; */
  color: #ffffff;
  font-weight: bold;
  /* margin-left: -13px; */
  /* text-align: center; */
  line-height: 2;
}

.columnEllipsis {
  .divElipsis {
    white-space: nowrap;
    width: 150px !important;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
  .commentEllipsis {
    white-space: nowrap;
    width: 300px !important;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}

.tooltipText {
  font-weight: 400;
  font-size: 14px;
  color: #696f7a;
  text-align: left;
}
.tooltipChild {
  width: 100%;
  height: 100%;
}
.tooltipComment {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: max-content !important;
  max-width: 300px !important;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  padding: 10px;
  display: none;
  left: -10px;
  padding-right: 15px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

  &::before {
    content: '';
    top: -33px;
    left: 10px;
    z-index: 2010;
    clip-path: polygon(50% 61%, 0% 100%, 100% 100%);
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    height: 35px;
    width: 30px;
    position: absolute;
  }
}
.tooltipDemo {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: max-content !important;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  padding: 10px;
  display: none;
  left: -10px;
  padding-right: 15px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

  &::before {
    content: '';
    top: -33px;
    left: 10px;
    z-index: 2010;
    clip-path: polygon(50% 61%, 0% 100%, 100% 100%);
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    height: 35px;
    width: 30px;
    position: absolute;
  }
}
.tooltip {
  background: #ffffff;
  border-radius: 4px;
  z-index: 2000;
  width: fit-content;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  padding: 10px;
  display: none;
  left: -10px;
  padding-right: 15px;
  box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);

  &::before {
    content: '';
    top: -33px;
    left: 10px;
    z-index: 2010;
    clip-path: polygon(50% 61%, 0% 100%, 100% 100%);
    background: #ffffff;
    box-shadow: 0px 5px 20px rgba(169, 186, 199, 0.25);
    height: 35px;
    width: 30px;
    position: absolute;
  }
}

.rounded {
  cursor: pointer;
  position: relative;
  &:hover > div {
    display: block;
    word-break: break-all;
    width: 100%;
    text-align: left;
  }
}
