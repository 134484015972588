@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9e9e9e;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  bottom: 1px;
  top: -3px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #979fac;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #304059;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  left: -8px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  background: #fafafa;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  left: 0px;
}

.titleClass {
  @include font29;
  @include p-small;
  color: $neutral1;
  font-family: 'Montserrat';
  margin-bottom: 11px;
  text-transform: capitalize;
  @media (max-width: $md) {
    margin-bottom: 13px;
  }
  @media (max-width: $xxs) {
    margin-bottom: 11px;
  }
}

.mainClass {
  display: flex;
  align-items: center;
  h6 {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.17px;
    color: #363636;
    margin-left: 10px;
    margin-bottom: 0px;
  }
}

.errorMessage {
  position: absolute;
  display: block;
  font-size: 12px;
  margin-left: 5px;
  color: #ff5050;
}
