@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.bgWeight {
  background: #ffffff;
  box-shadow: -6px 8px 11px rgba(0, 0, 0, 0.11);
  border-radius: 8px 0px 0px 8px;
  position: fixed;
  z-index: 300;
  top: calc(100vh - 620px);
  max-width: 522px;
  width: 100%;
  padding: 36px;
  @media (max-height: 660px) {
    top: 20vh;
    padding: 15px;
  }
  .cross {
    display: flex;
    justify-content: flex-end;
    img {
      cursor: pointer;
    }
  }
  h1 {
    font-weight: 600;
    font-size: 18px;
    margin: 15px 0px;
    color: #673e9b;
    @media (max-height: 660px) {
      padding: 5px;
      margin: 5px 0px;
    }
  }
  .table {
    @media (max-width: 660px) {
      max-width: 560px;
      overflow-x: auto;
      width: 100%;
    }
    &::-webkit-scrollbar {
      width: 5px !important; /* Remove scrollbar space */
      background: #e6e7e8; /* Optional: just make scrollbar invisible */
      height: 5px !important;
    }
    .innerTable {
      overflow-x: auto;
      overflow-y: auto;
      &::-webkit-scrollbar:vertical {
        width: 5px; /* Remove scrollbar space */
        background: #e6e7e8; /* Optional: just make scrollbar invisible */
        height: 5px;
        border-radius: 8px;
        display: block !important;
      }

      @media (max-width: 660px) {
        width: 569px;
      }
      @media (max-height: 660px) {
        max-height: 40vh;
        overflow-y: auto;
      }
    }
    .header {
      background: #f6fafd;
      border-radius: 8px;
      padding: 20px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.17px;
        color: #363636;
        width: 85px;
        @media (max-width: 660px) {
          width: 105px;
        }
      }
    }
    .rows {
      max-height: 250px;
      overflow-y: auto;
      &::-webkit-scrollbar:vertical {
        width: 5px; /* Remove scrollbar space */
        background: #e6e7e8; /* Optional: just make scrollbar invisible */
        height: 5px;
        border-radius: 8px;
        display: block !important;
      }
    }
    .tableBody {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      padding: 0px 12px;
      p {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.17px;
        color: #696f7a;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .img {
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }
  .btnDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    @media (max-height: 660px) {
      margin-top: 15px;
    }

    @media (max-width: 500px) {
      flex-direction: column;
    }
    .btnClassModal {
      height: 40px !important;
      width: 100%;
      background-color: #673e9b;
    }
    .btnClassUncheckModal {
      padding: 0px;
      height: 40px !important;
      width: 100%;
      background-color: #fff;
      margin-top: 10px;
      justify-content: flex-start !important;
      @media (max-width: 500px) {
        justify-content: center !important;
      }
      span {
        color: #673e9b !important;
      }
    }
  }
}
.field {
  width: 42%;
}
.inputField {
  input {
    height: 20px !important;
    width: 22px;
    padding: 7px 0px 7px 1px;
  }
}
.crossIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin: 4px 0 0 7px;
}
.okBtn {
  font-weight: 600 !important;
  font-size: 14px !important;
  cursor: pointer;
  color: #673e9b !important;
  margin: 3px 0 0 5px;
}
