@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';
.profileSectionWraper {
  padding: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 7px;
  background-color: #ffffff;
  .dates {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;

    @media (max-width: $md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: $xs) {
      grid-template-columns: 1fr;
    }
    .setMargin {
      @media (max-width: $md) {
        margin-left: 0px;
      }
    }
  }
  .attorneySectionOne {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    margin-bottom: 10px;
    @media (max-width: $md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: $xs) {
      grid-template-columns: 1fr;
    }
    .setMargin {
      @media (max-width: $md) {
        margin-left: 0px;
      }
    }
  }

  .lawSection {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;

    @media (max-width: $md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: $xs) {
      grid-template-columns: 1fr;
    }
  }
}

.placePreference {
  padding: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 7px;
  background-color: #ffffff;

  .preferance {
    @include header;
    color: $attorney1;
  }
  .preferanceGreen {
    @include header;
    color: #39695b !important;
  }
  .choices {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    margin: 20px 0px 50px 0px;
    @media (max-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.btnWraper {
  margin: 20px 0px;
  border: 1px solid red;
  background-color: transparent;
  height: 50px;
}
.btnText {
  color: red !important;
}

.header {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: white;

  .buttonStyle {
    background-color: #39695b;
    height: 48px;
    width: 237px;
    border-radius: 24px;
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .bottomText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #39695b;
    cursor: pointer;
  }
}

.labelTag {
  @include labelTag;
  margin-bottom: 5px;
}

.commentHead {
  @include header;
  color: $attorney1;
}
.date {
  color: $neutral2;
}

.commentValue {
  @include labelTag;
  color: $neutral2;
}

.zDynamic {
  z-index: 3000 !important;
}
.zDynamicDate {
  z-index: 4000 !important;
}
.zDynamicState {
  z-index: 100 !important;
}
.zDynamicState2 {
  z-index: 1 !important;
}
.modalHeader {
  text-align: center;
  .deleteText {
    @include normal-tag;
    line-height: 100%;
    color: $attorney1;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  p {
    @include p-small;
    padding: 0px 20px;
    color: $neutral1;
    line-height: 150%;
    margin-bottom: 24px;
  }
  button {
    width: 100%;
    margin-bottom: 30px;
  }
  .cancelText {
    @include tableHeader;
    color: $attorney1;
    cursor: pointer;
  }
}

.birthDatePopper {
  @media (max-width: 1024px) {
    left: unset !important;
    right: 0 !important;
  }
}
