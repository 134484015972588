.mainDiv {
  max-width: 380px !important;

  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
  }
  .btnClassModal {
    height: 40px !important;
    width: 250px;
    background-color: #304059;
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 250px;
    background-color: #fff;
    margin-top: 10px;
    span {
      color: #304059 !important;
    }
  }
}
h6 {
  font-weight: 600;
  font-size: 18px;
  color: #304059;
  margin-bottom: 10px;
  text-align: left;
}
.input {
  margin-top: 15px;
  img {
    top: 15px !important;
    height: 20px !important;
    width: 20px !important;
  }
}
