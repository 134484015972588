@import 'styles/_breakpoints.scss';
@import 'styles/_colors.scss';
@import 'styles/_mixin.scss';

.wraper {
  margin-top: 48px;
  padding-bottom: 30px;
  .scrollDiv {
    @media (max-width: $sm) {
      background-color: #363636;
      min-width: 900px;
    }
  }
}
.topText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #a93e71;
  margin-top: 40px;
}
.scrollDiv {
  @media (max-width: $md) {
    max-width: 900px;
    overflow-x: scroll;
    &::-webkit-scrollbar:horizontal {
      width: 5px; /* Remove scrollbar space */
      background: #e6e7e8; /* Optional: just make scrollbar invisible */
      height: 5px;
      border-radius: 8px;
      display: block !important;
    }
  }
  .innerScrollDiv {
    @media (max-width: $sm) {
      width: 800px;
    }
  }
}
.mainDiv {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #ffffff;
  padding: 24px 10px;
  border-radius: 5px;
  margin-top: 10px;
  display: grid;
  padding-left: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  .p1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #363636;
  }
  .p2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #363636;
  }
}
.iconsDiv {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 20px;
  img {
    cursor: pointer;
  }
}
.btnWraper {
  height: 40px;
  margin-top: 20px;
}
.modalHeader {
  text-align: center;
  .deleteText {
    @include normal-tag;
    line-height: 100%;
    color: #a93e71;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  p {
    @include p-small;
    padding: 0px 20px;
    color: $neutral1;
    line-height: 150%;
    margin-bottom: 24px;
  }
  button {
    width: 100%;
    margin-bottom: 30px;
  }
  .cancelText {
    @include tableHeader;
    color: #a93e71;
    cursor: pointer;
  }
}

.btnWraper {
  background-color: #a93e71;
  padding: 26px;
}
