.placedModal {
  max-width: 400px !important;
  .modalTitle {
    font-weight: 600;
    font-size: 26px;
    color: #39695b;
    margin-bottom: 10px;
    text-align: center !important;
  }
  .modalTitleInner {
    margin-bottom: 10px;
    text-align: center !important;
    font-size: 18px;
    padding: 14px;
  }
  .upperDiv {
    height: 180px;
    overflow: auto;
    .rowDiv {
      padding: 0px 20px;
      width: 100%;
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
  }
  .btnClassModal {
    height: 40px !important;
    width: 180px;
    background-color: #39695b;
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 180px;
    background-color: #fff;
    margin-top: 10px;
    span {
      color: #39695b !important;
    }
  }
}

.mainDivWidth {
  max-width: 900px !important;
}

.mainDiv {
  max-width: 900px !important;

  &::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    padding: 100px 100px !important;
    display: block !important;
  }
  .modalTitle {
    font-weight: 600;
    font-size: 26px;
    color: #39695b;
    margin-bottom: 10px;
    text-align: center !important;
  }
  .modalTitleInner {
    margin-bottom: 10px;
    text-align: center !important;
    font-size: 18px;
    padding: 14px;
  }
  .upperDiv {
    height: 180px;
    overflow: auto;
    .rowDiv {
      padding: 0px 20px;
      width: 100%;
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .mainBtnDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
  }
  .btnClassModal {
    height: 40px !important;
    width: 180px;
    background-color: #39695b;
  }
  .btnClassUncheckModal {
    height: 40px !important;
    width: 180px;
    background-color: #fff;
    margin-top: 10px;
    span {
      color: #39695b !important;
    }
  }
  .btnClassModal {
    margin-top: 250px;
  }
}

.upperDiv::-webkit-scrollbar {
  width: 7.5px !important; /* Remove scrollbar space */
  background: #e6e7e8 !important; /* Optional: just make scrollbar invisible */
  border-radius: 10px;
}
/* Optional: show position indicator in red */
.upperDiv::-webkit-scrollbar-thumb {
  background: #c7c8ca !important;
  border-radius: 10px;
}

.innerDiv1 {
  display: flex;
  justify-content: space-between;
  background: #f6fafd;
  border-radius: 8px;
  margin-top: 20px;
  padding: 10px 10px;
  h6 {
    font-weight: 700;
    font-size: 14px;
    color: #363636;
    width: 100%;
    margin: 0;
  }
}

.innerDiv2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 5px;
  .ele {
    width: calc(100% - 15px);
    font-size: 14px;
    font-weight: 600;
    color: #363636;
  }
}

.zDynamicState2 {
  margin: 0px 5px;
  margin-top: 2px !important;
}

.zDynamicState3 {
  top: -1px;
}

.statusDropdown {
  margin-top: 20px;
  width: 100%;
  min-height: 10px !important;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: block !important;
  }

  .innerDiv {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    p {
      margin: 10px;
    }
  }
}

.statusDropdownWithFourArea {
  margin-top: 20px;
  width: 100%;
  min-height: 10px !important;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar:vertical {
    width: 5px; /* Remove scrollbar space */
    background: #e6e7e8; /* Optional: just make scrollbar invisible */
    height: 5px;
    border-radius: 8px;
    display: block !important;
  }

  .innerDiv {
    cursor: pointer;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    p {
      margin: 10px;
    }
  }
}

.statusModalWidth {
  width: 100%;
}

.right {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  text-align: rightS;
  padding: 20px 0px;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}
